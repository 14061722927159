import { Box, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import InfoTooltip from 'components/InfoTooltip'
import RadioOptionsForm from 'components/RadioOptionsForm/RadioOptionsForm'
import ViewWrapper from 'components/ViewWrapper'
import { Customize, PostCodeDetail } from 'graphql/types'
import { InstallationDetailsFields } from 'pages/InstallationDetails/useInstallationDetailsReducer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { ContactDataState, initialContactDataAddressState } from 'store/ContactData/ContactData.reducer'
import { Error, InstallationDetailsState, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { UserInfo, getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { salutationIsCompany } from 'utils/testable/functions'
import ContactFields from '../../components/ContactFields'
import colors from '../../theme/colors'
import TestIDs from '../../utils/TestIDs'
import { useContactDataReducer } from './useContactDataReducer'

export interface RenderContactDataProps {
    b2b: boolean
    errors: Error[]
    contactData: ContactDataState
    availability: AvailabilityCheckState
    installationDetails: InstallationDetailsState
    inLineErrors: string[]
    setContactDataPartial: (payload: Partial<ContactDataState>) => void
    setContactData?: (payload: ContactDataState) => void
    setEditContactAddress?: (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
        delivery: boolean,
    ) => void
    customizeJsData: Customize | undefined
    userInfo: UserInfo | null
    displayDeliveryAddress: boolean
    onChangeInstallationFields: (field: InstallationDetailsFields, value: string | number) => void
}

export const renderContactData = (props: RenderContactDataProps): JSX.Element => {
    const {
        availability,
        b2b,
        contactData,
        customizeJsData,
        errors,
        inLineErrors,
        setContactDataPartial,
        userInfo,
        setContactData,
        setEditContactAddress,
        displayDeliveryAddress,
        installationDetails,
        onChangeInstallationFields,
    } = props

    const { t } = useTranslation()

    let invalidBirthday = ''
    if (inLineErrors.includes('birthDate_invalid')) {
        invalidBirthday = 'invalid'
    } else if (inLineErrors.includes('birthDate_min')) {
        invalidBirthday = 'min'
    } else if (inLineErrors.includes('birthDate_max')) {
        invalidBirthday = 'max'
    }

    const renderTextfield = (
        value: string,
        field: InstallationDetailsFields,
        onChange: (field: InstallationDetailsFields, value: string) => void,
        required: boolean,
        maxLength: number,
        noTooltip?: boolean,
    ): JSX.Element => {
        const requiredIcon = required ? ' *' : ''
        return (
            <Grid item xs={12} sm={4}>
                <Box height={1} display={'flex'}>
                    <Grid item xs={11} style={{ marginRight: 8 }}>
                        <TextField
                            {...TestIDs.GET_INSTALLATION_DETAILS('TEXTFIELD', field)}
                            variant={'outlined'}
                            fullWidth={true}
                            label={t('installationDetailsStrings.' + field + 'Label') + requiredIcon}
                            value={value}
                            onChange={(e): void => {
                                onChange(field, e.target.value)
                            }}
                            type={'text'}
                            inputProps={{ maxLength: maxLength }}
                        />
                    </Grid>
                    {!noTooltip && (
                        <Grid item>
                            <Box width={1} height={1} className={'InfoTooltipContainer'} display={'flex'}>
                                <InfoTooltip title={t('installationDetailsStrings.' + field + 'Info') as string} />
                            </Box>
                        </Grid>
                    )}
                </Box>
            </Grid>
        )
    }

    return (
        <>
            <ContactFields
                testCategory={'PERSONAL'}
                salutationOptions={
                    customizeJsData ? customizeJsData.contactDataViewConfiguration.salutationOptionsPersonal : []
                }
                city={availability.selectedCity}
                houseNumber={availability.selectedHouseNumber}
                isAddressDisabled={true}
                lastName={contactData.personalLastName}
                setLastName={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalLastName: v })
                        : setContactDataPartial({ personalLastName: v })
                }
                name={contactData.personalName}
                setName={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalName: v })
                        : setContactDataPartial({ personalName: v })
                }
                salutation={contactData.personalSalutation}
                title={contactData.personalTitle}
                setSalutation={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalSalutation: v })
                        : setContactDataPartial({ personalSalutation: v })
                }
                setTitle={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalTitle: v })
                        : setContactDataPartial({ personalTitle: v })
                }
                street={availability.selectedStreet}
                zip={availability.zip}
                showAdditionalAddressInfo={true}
                additionalInfoAddr={contactData.personalAdditionalAddressInfo}
                setAdditionalInfoAddr={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalAdditionalAddressInfo: v })
                        : setContactDataPartial({ personalAdditionalAddressInfo: v })
                }
                company={b2b || salutationIsCompany(contactData.personalSalutation) ? contactData.company : undefined}
                setCompany={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, company: v })
                        : setContactDataPartial({ company: v })
                }
                companyLegalForm={b2b ? contactData.companyLegalForm : undefined}
                setCompanyLegalForm={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyLegalForm: v })
                        : setContactDataPartial({ companyLegalForm: v })
                }
                companyLocation={b2b ? contactData.companyLocation : undefined}
                setCompanyLocation={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyLocation: v })
                        : setContactDataPartial({ companyLocation: v })
                }
                companyRegisterEntry={b2b ? contactData.companyRegisterEntry : undefined}
                setCompanyRegistrationEntry={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyRegisterEntry: v })
                        : setContactDataPartial({ companyRegisterEntry: v })
                }
                companyId={b2b ? contactData.companyId : undefined}
                setCompanyId={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyId: v })
                        : setContactDataPartial({ companyId: v })
                }
                contactDataViewConfiguration={
                    customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                }
            />
            <Box marginTop={1} width={1}>
                <Grid container item spacing={1}>
                    {userInfo &&
                        userInfo.roles.includes('Vertriebspartner') &&
                        customizeJsData &&
                        customizeJsData.contactDataViewConfiguration.activateSalesPartnerEmail === true && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="caption">{t('salesPartnerEmailText')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        {...TestIDs.GET_CONTACT_DATA('NO_CUSTOMER_EMAIL', 'PERSONAL')}
                                        control={
                                            <Checkbox
                                                onClick={(): void => {
                                                    if (setContactData) {
                                                        setContactData({
                                                            ...contactData,
                                                            useSalesPartnerEmail: !contactData.useSalesPartnerEmail,
                                                        })
                                                        if (!contactData.useSalesPartnerEmail) {
                                                            setContactData({
                                                                ...contactData,
                                                                personalEmail:
                                                                    customizeJsData.contactDataViewConfiguration
                                                                        .salesPartnerEmail,
                                                            })
                                                        } else if (
                                                            contactData.useSalesPartnerEmail &&
                                                            customizeJsData.contactDataViewConfiguration
                                                                .salesPartnerEmail === contactData.personalEmail
                                                        ) {
                                                            setContactData({
                                                                ...contactData,
                                                                personalEmail: '',
                                                            })
                                                        }
                                                    } else {
                                                        const updateData: Partial<ContactDataState> = {
                                                            useSalesPartnerEmail: !contactData.useSalesPartnerEmail,
                                                        }
                                                        if (!contactData.useSalesPartnerEmail) {
                                                            updateData.personalEmail =
                                                                customizeJsData.contactDataViewConfiguration.salesPartnerEmail
                                                        } else if (
                                                            contactData.useSalesPartnerEmail &&
                                                            customizeJsData.contactDataViewConfiguration
                                                                .salesPartnerEmail === contactData.personalEmail
                                                        ) {
                                                            updateData.personalEmail = ''
                                                        }
                                                        setContactDataPartial(updateData)
                                                    }
                                                }}
                                                style={{ color: colors.black, padding: 0 }}
                                            />
                                        }
                                        label={t('customerNoEmail')}
                                        checked={contactData.useSalesPartnerEmail}
                                    />
                                </Grid>
                            </>
                        )}
                    <Grid item xs={12} md={9}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('EMAIL', 'PERSONAL')}
                            inputProps={{
                                maxLength: 50,
                                className: inLineErrors.includes('email') ? 'TextError' : '',
                            }}
                            error={inLineErrors.includes('email')}
                            helperText={
                                inLineErrors.includes('email') &&
                                t('error.' + ViewType.CONTACT_DATA + '.emailFormatIsNotValid')
                            }
                            fullWidth={true}
                            label={t('contactDataStrings.email')}
                            variant={'outlined'}
                            value={contactData.personalEmail}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalEmail: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalEmail: e.currentTarget.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputMask
                            mask={'99.99.9999'}
                            alwaysShowMask={false}
                            placeholder={''}
                            value={contactData.personalBirthDate}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalBirthDate: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalBirthDate: e.target.value })
                            }
                        >
                            {(): JSX.Element => (
                                <TextField
                                    InputProps={{
                                        className: invalidBirthday.length > 0 ? 'TextError' : '',
                                    }}
                                    fullWidth={true}
                                    label={t('contactDataStrings.birthDate')}
                                    variant={'outlined'}
                                    error={invalidBirthday.length > 0}
                                    helperText={
                                        invalidBirthday.length > 0 &&
                                        t('error.' + ViewType.CONTACT_DATA + '.birthDate.' + invalidBirthday)
                                    }
                                    {...TestIDs.GET_CONTACT_DATA('BIRTH_DATE', 'PERSONAL')}
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('TELEPHONE', 'PERSONAL')}
                            fullWidth={true}
                            label={t('contactDataStrings.telephone')}
                            variant={'outlined'}
                            value={contactData.personalTelephone}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalTelephone: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalTelephone: e.currentTarget.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('MOBILE_PHONE', 'PERSONAL')}
                            fullWidth={true}
                            label={t('contactDataStrings.mobilePhone')}
                            variant={'outlined'}
                            value={contactData.personalMobilePhone}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalMobilePhone: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalMobilePhone: e.currentTarget.value })
                            }
                        />
                    </Grid>

                    {b2b && (
                        <Grid item xs={12}>
                            <Typography style={{ paddingTop: 10 }} variant="h2">
                                {t('b2bHeadline')}
                            </Typography>
                            <Typography variant="body2">{t('b2bContent')}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box width={1}>
                {displayDeliveryAddress && (
                    <>
                        <Box width={1} marginTop={2}>
                            <FormControlLabel
                                {...TestIDs.GET_CONTACT_DATA('TOGGLE', 'DELIVERY')}
                                control={
                                    <Checkbox
                                        onClick={(): void => {
                                            setContactData
                                                ? setContactData({
                                                      ...contactData,
                                                      deviatingDeliveryAddress: !contactData.deviatingDeliveryAddress,
                                                      deviatingDeliveryDhl: false,
                                                      ...initialContactDataAddressState,
                                                  })
                                                : setContactDataPartial({
                                                      deviatingDeliveryAddress: !contactData.deviatingDeliveryAddress,
                                                      deviatingDeliveryDhl: false,
                                                      ...initialContactDataAddressState,
                                                  })
                                        }}
                                        style={{ color: colors.black, padding: 0 }}
                                    />
                                }
                                label={t('contactDataStrings.deviatingDeliveryAddress')}
                                checked={contactData.deviatingDeliveryAddress}
                            />
                        </Box>
                        {customizeJsData?.contactDataViewConfiguration.deliveryAddressConfiguration.dhlEnabled && (
                            <Box width={1} marginTop={2}>
                                <FormControlLabel
                                    {...TestIDs.GET_CONTACT_DATA('TOGGLE', 'DELIVERY')}
                                    control={
                                        <Checkbox
                                            onClick={(): void => {
                                                setContactData
                                                    ? setContactData({
                                                          ...contactData,
                                                          deviatingDeliveryDhl: !contactData.deviatingDeliveryDhl,
                                                          deviatingDeliveryAddress: false,
                                                          ...initialContactDataAddressState,
                                                      })
                                                    : setContactDataPartial({
                                                          deviatingDeliveryDhl: !contactData.deviatingDeliveryDhl,
                                                          deviatingDeliveryAddress: false,
                                                          ...initialContactDataAddressState,
                                                      })
                                            }}
                                            style={{ color: colors.black, padding: 0, marginBottom: 10 }}
                                        />
                                    }
                                    label={t('contactDataStrings.deviatingDeliveryDhl')}
                                    checked={contactData.deviatingDeliveryDhl}
                                />
                            </Box>
                        )}

                        <Collapse
                            className={'ContactDataCollapse'}
                            in={
                                contactData.deviatingDeliveryAddress ||
                                (contactData.deviatingDeliveryDhl && contactData.deviatingDeliveryDhl)
                            }
                            unmountOnExit={true}
                            onEnter={(): void => {
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          deliveryStreet: contactData.deviatingDeliveryDhl
                                              ? t('contactDataStrings.DHLpackstation')
                                              : '',
                                          deliveryLastName: '',
                                          deliveryName: '',
                                          deliverySalutation: '',
                                          deliveryTitle: '',
                                          deliveryAdditionalAddrInfo: undefined,
                                          deliveryCompany: '',
                                          deliveryCompanyLegalForm: '',
                                          deliveryCity: '',
                                          deliveryHouseNumber: '',
                                          deliveryZip: '',
                                      })
                                    : setContactDataPartial({
                                          deliveryStreet: contactData.deviatingDeliveryDhl
                                              ? t('contactDataStrings.DHLpackstation')
                                              : '',
                                          deliveryLastName: '',
                                          deliveryName: '',
                                          deliverySalutation: '',
                                          deliveryTitle: '',
                                          deliveryAdditionalAddrInfo: undefined,
                                          deliveryCompany: '',
                                          deliveryCompanyLegalForm: '',
                                          deliveryCity: '',
                                          deliveryHouseNumber: '',
                                          deliveryZip: '',
                                      })
                            }}
                        >
                            <ContactFields
                                streetLabel={
                                    contactData.deviatingDeliveryDhl
                                        ? t('contactDataStrings.DHLpackstation')
                                        : undefined
                                }
                                zipLabel={
                                    contactData.deviatingDeliveryDhl
                                        ? t('contactDataStrings.DHLpackstationZip')
                                        : undefined
                                }
                                cityLabel={
                                    contactData.deviatingDeliveryDhl
                                        ? t('contactDataStrings.DHLpackstationCity')
                                        : undefined
                                }
                                houseNumberLabel={
                                    contactData.deviatingDeliveryDhl
                                        ? t('contactDataStrings.DHLpackstationNumber')
                                        : undefined
                                }
                                additionalAddressInfoLabel={
                                    contactData.deviatingDeliveryDhl
                                        ? t('contactDataStrings.DHLpackstationAdditionalInfo')
                                        : undefined
                                }
                                salutationOptions={
                                    customizeJsData
                                        ? customizeJsData.contactDataViewConfiguration.salutationOptionsDeliver
                                        : []
                                }
                                testCategory={'DELIVERY'}
                                city={contactData.deliveryCity}
                                houseNumber={contactData.deliveryHouseNumber}
                                isAddressDisabled={false}
                                lastName={contactData.deliveryLastName}
                                name={contactData.deliveryName}
                                salutation={contactData.deliverySalutation}
                                setLastName={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryLastName: v,
                                          })
                                        : setContactDataPartial({ deliveryLastName: v })
                                }
                                setName={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryName: v,
                                          })
                                        : setContactDataPartial({ deliveryName: v })
                                }
                                setSalutation={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliverySalutation: v,
                                          })
                                        : setContactDataPartial({ deliverySalutation: v })
                                }
                                setTitle={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryTitle: v,
                                          })
                                        : setContactDataPartial({ deliveryTitle: v })
                                }
                                street={contactData.deviatingDeliveryDhl ? 'Packstation' : contactData.deliveryStreet}
                                title={contactData.deliveryTitle}
                                zip={contactData.deliveryZip}
                                setCity={(v) => setContactDataPartial({ deliveryCity: v })}
                                setHouseNumber={(v) => setContactDataPartial({ deliveryHouseNumber: v })}
                                setStreet={(v) => setContactDataPartial({ deliveryStreet: v })}
                                setZip={(v) => setContactDataPartial({ deliveryZip: v })}
                                handleAddressSelected={(data: PostCodeDetail) => {
                                    if (setEditContactAddress) {
                                        setEditContactAddress(
                                            data.address.postcode,
                                            data.address.locality,
                                            data.address.street,
                                            data.address.buildingNumber.toString(),
                                            data.address.buildingNumberAddition,
                                            true,
                                        )
                                    } else {
                                        setContactDataPartial({ deliveryZip: data.address.postcode })
                                        setContactDataPartial({ deliveryCity: data.address.locality })
                                        setContactDataPartial({ deliveryStreet: data.address.street })
                                        setContactDataPartial({
                                            deliveryHouseNumber: data.address.buildingNumber.toString(),
                                        })
                                        setContactDataPartial({
                                            deliveryAdditionalAddrInfo: data.address.buildingNumberAddition,
                                        })
                                    }
                                }}
                                errors={errors}
                                showAutoComplete={
                                    contactData.deviatingDeliveryDhl
                                        ? false
                                        : customizeJsData?.contactDataViewConfiguration
                                              .useAutoCompleteDeliveryAddress ?? true
                                }
                                showCountryAddress={
                                    customizeJsData?.contactDataViewConfiguration.showCountryFieldDeliveryAddress ??
                                    true
                                }
                                showAdditionalAddressInfo={true}
                                additionalInfoAddr={contactData.deliveryAdditionalAddrInfo}
                                setAdditionalInfoAddr={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryAdditionalAddrInfo: v,
                                          })
                                        : setContactDataPartial({ deliveryAdditionalAddrInfo: v })
                                }
                                countryAddr={contactData.deliveryCountryAddr}
                                setCountryAddr={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryCountryAddr: v,
                                          })
                                        : setContactDataPartial({ deliveryCountryAddr: v })
                                }
                                company={
                                    b2b || salutationIsCompany(contactData.deliverySalutation)
                                        ? contactData.deliveryCompany
                                        : undefined
                                }
                                setCompany={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryCompany: v,
                                          })
                                        : setContactDataPartial({ deliveryCompany: v })
                                }
                                companyLegalForm={contactData.deliveryCompanyLegalForm}
                                setCompanyLegalForm={(v) =>
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deliveryCompanyLegalForm: v,
                                          })
                                        : setContactDataPartial({ deliveryCompanyLegalForm: v })
                                }
                                contactDataViewConfiguration={
                                    customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                                }
                            />
                        </Collapse>
                    </>
                )}

                <Box width={1} marginTop={2}>
                    <FormControlLabel
                        {...TestIDs.GET_CONTACT_DATA('TOGGLE', 'BILLING')}
                        control={
                            <Checkbox
                                onClick={() => {
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deviatingBillingAddress: !contactData.deviatingBillingAddress,
                                          })
                                        : setContactDataPartial({
                                              deviatingBillingAddress: !contactData.deviatingBillingAddress,
                                          })
                                }}
                                style={{ color: colors.black, padding: 0 }}
                            />
                        }
                        label={t('contactDataStrings.deviatingBillingAddress')}
                        checked={contactData.deviatingBillingAddress}
                    />
                </Box>
                <Collapse
                    className={'ContactDataCollapse'}
                    in={contactData.deviatingBillingAddress}
                    unmountOnExit={true}
                    onEnter={(): void => {
                        setContactData
                            ? setContactData({
                                  ...contactData,
                                  billingLastName: '',
                                  billingName: '',
                                  billingSalutation: '',
                                  billingTitle: '',
                                  billingAdditionalAddrInfo: undefined,
                                  billingCompany: '',
                                  billingCompanyLegalForm: '',
                                  billingCity: '',
                                  billingHouseNumber: '',
                                  billingStreet: '',
                                  billingZip: '',
                              })
                            : setContactDataPartial({
                                  billingLastName: '',
                                  billingName: '',
                                  billingSalutation: '',
                                  billingTitle: '',
                                  billingAdditionalAddrInfo: undefined,
                                  billingCompany: '',
                                  billingCompanyLegalForm: '',
                                  billingCity: '',
                                  billingHouseNumber: '',
                                  billingStreet: '',
                                  billingZip: '',
                              })
                    }}
                >
                    <ContactFields
                        salutationOptions={
                            customizeJsData ? customizeJsData.contactDataViewConfiguration.salutationOptionsBilling : []
                        }
                        testCategory={'BILLING'}
                        city={contactData.billingCity}
                        houseNumber={contactData.billingHouseNumber}
                        isAddressDisabled={false}
                        lastName={contactData.billingLastName}
                        name={contactData.billingName}
                        salutation={contactData.billingSalutation}
                        setLastName={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingLastName: v,
                                  })
                                : setContactDataPartial({ billingLastName: v })
                        }
                        setName={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingName: v,
                                  })
                                : setContactDataPartial({ billingName: v })
                        }
                        setSalutation={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingSalutation: v,
                                  })
                                : setContactDataPartial({ billingSalutation: v })
                        }
                        setTitle={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingTitle: v,
                                  })
                                : setContactDataPartial({ billingTitle: v })
                        }
                        street={contactData.billingStreet}
                        title={contactData.billingTitle}
                        zip={contactData.billingZip}
                        setZip={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingZip: v,
                                  })
                                : setContactDataPartial({ billingZip: v })
                        }
                        setCity={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingCity: v,
                                  })
                                : setContactDataPartial({ billingCity: v })
                        }
                        setHouseNumber={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingHouseNumber: v,
                                  })
                                : setContactDataPartial({ billingHouseNumber: v })
                        }
                        setStreet={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingStreet: v,
                                  })
                                : setContactDataPartial({ billingStreet: v })
                        }
                        handleAddressSelected={(data: PostCodeDetail) => {
                            if (setEditContactAddress) {
                                setEditContactAddress(
                                    data.address.postcode,
                                    data.address.locality,
                                    data.address.street,
                                    data.address.buildingNumber.toString(),
                                    data.address.buildingNumberAddition,
                                    false,
                                )
                            } else {
                                setContactDataPartial({ billingZip: data.address.postcode })
                                setContactDataPartial({ billingCity: data.address.locality })
                                setContactDataPartial({ billingStreet: data.address.street })
                                setContactDataPartial({ billingHouseNumber: data.address.buildingNumber.toString() })
                                setContactDataPartial({
                                    billingAdditionalAddrInfo: data.address.buildingNumberAddition,
                                })
                            }
                        }}
                        errors={errors}
                        showAutoComplete={
                            customizeJsData?.contactDataViewConfiguration.useAutoCompleteBillingAddress ?? true
                        }
                        showCountryAddress={
                            customizeJsData?.contactDataViewConfiguration.showCountryFieldBillingAddress ?? true
                        }
                        showAdditionalAddressInfo={true}
                        additionalInfoAddr={contactData.billingAdditionalAddrInfo}
                        setAdditionalInfoAddr={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingAdditionalAddrInfo: v,
                                  })
                                : setContactDataPartial({ billingAdditionalAddrInfo: v })
                        }
                        countryAddr={contactData.billingCountryAddr}
                        setCountryAddr={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingCountryAddr: v,
                                  })
                                : setContactDataPartial({ billingCountryAddr: v })
                        }
                        company={
                            b2b || salutationIsCompany(contactData.billingSalutation)
                                ? contactData.billingCompany
                                : undefined
                        }
                        setCompany={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingCompany: v,
                                  })
                                : setContactDataPartial({ billingCompany: v })
                        }
                        companyLegalForm={contactData.billingCompanyLegalForm}
                        setCompanyLegalForm={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      billingCompanyLegalForm: v,
                                  })
                                : setContactDataPartial({ billingCompanyLegalForm: v })
                        }
                        contactDataViewConfiguration={
                            customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                        }
                    />
                </Collapse>
                {customizeJsData && customizeJsData.installationDetailsConfiguration.displayInContactPage && (
                    <Box marginTop={4} width={1}>
                        <Typography
                            variant={'h1'}
                            dangerouslySetInnerHTML={{
                                __html: t(customizeJsData.installationDetailsConfiguration.i18Key + 'DetailsHeader'),
                            }}
                        />
                        <Typography variant={'h4'}>
                            {t(customizeJsData.installationDetailsConfiguration.i18Key + 'DetailsSubheader')}
                        </Typography>
                        <Grid
                            container
                            style={{ marginTop: 3 }}
                            spacing={3}
                            className={'installationDetailsAppartmentData'}
                        >
                            {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                                renderTextfield(
                                    installationDetails.floorInformation,
                                    InstallationDetailsFields.FLOOR_INFO,
                                    onChangeInstallationFields,
                                    customizeJsData?.installationDetailsConfiguration?.appartmentData
                                        .floorInformationRequired,
                                    50,
                                )}
                            {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                                renderTextfield(
                                    installationDetails.flatPosition,
                                    InstallationDetailsFields.FLAT_POSITION,
                                    onChangeInstallationFields,
                                    customizeJsData?.installationDetailsConfiguration?.appartmentData
                                        .flatPositionRequired,
                                    50,
                                )}
                            {customizeJsData?.installationDetailsConfiguration?.appartmentData &&
                                renderTextfield(
                                    installationDetails.flatNumber ?? '',
                                    InstallationDetailsFields.FLAT_NUMBER,
                                    onChangeInstallationFields,
                                    customizeJsData?.installationDetailsConfiguration?.appartmentData
                                        .flatNumberRequired,
                                    50,
                                )}
                        </Grid>
                    </Box>
                )}

                {customizeJsData && customizeJsData.contactDataViewConfiguration.existingCustomerForm && (
                    <RadioOptionsForm
                        mainTitle={t('contactDataStrings.existingCustomerForm.header')}
                        configurationOptions={[
                            {
                                columnValue: 12,
                                label: t('contactDataStrings.existingCustomerForm.options.yes'),
                                mobileColumnValue: 12,
                                value: 'yes',
                            },
                            {
                                columnValue: 12,
                                label: t('contactDataStrings.existingCustomerForm.options.no'),
                                mobileColumnValue: 12,
                                value: 'no',
                            },
                        ]}
                        textFieldTriggerValue={'yes'}
                        bodyText={t('contactDataStrings.existingCustomerForm.subheader')}
                        onTextFieldChange={(value) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      existingCustomerNumber: value,
                                  })
                                : setContactDataPartial({ existingCustomerNumber: value })
                        }
                        onSelectOption={(value) => {
                            if (setContactData) {
                                setContactData({
                                    ...contactData,
                                    existingCustomerRadio: value,
                                    existingCustomerNumber: '',
                                })
                            } else {
                                setContactDataPartial({ existingCustomerNumber: '', existingCustomerRadio: value })
                            }
                        }}
                        initialSelectedOption={contactData.existingCustomerRadio}
                        initialTextFieldValue={contactData.existingCustomerNumber}
                    />
                )}

                <Box width={1} marginTop={2}>
                    <Typography>{t('contactDataStrings.helperText')}</Typography>
                </Box>
            </Box>
        </>
    )
}

const ContactData = (): JSX.Element => {
    const {
        b2b,
        currentView,
        errors,
        contactData,
        availability,
        areThereAnyMissingFields,
        inLineErrors,
        setContactDataPartial,
        customizeJsData,
        submitButtonTxt,
        displayDeliveryAddress,
        installationDetails,
        onChangeInstallationFields,
    } = useContactDataReducer()

    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disabledSubmit={areThereAnyMissingFields.length !== 0}
            header={getRoleAwareTranslationKey(userInfo, customizeJsData, 'contactDataHeader')}
            subHeader={getRoleAwareTranslationKey(userInfo, customizeJsData, 'contactDataSubheader')}
            viewType={currentView}
            errorCategory={ViewType.CONTACT_DATA}
            submitButtonText={submitButtonTxt}
            disableBackButton={customizeJsData && !customizeJsData.vzfConfiguration.disablePage ? true : false}
        >
            {renderContactData({
                availability,
                b2b,
                contactData,
                customizeJsData,
                errors,
                inLineErrors,
                setContactDataPartial,
                userInfo,
                displayDeliveryAddress,
                installationDetails,
                onChangeInstallationFields,
            })}
        </ViewWrapper>
    )
}

export default ContactData
