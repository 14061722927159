import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { generateVZFDataForText } from 'utils/generateVZFDataForText'
import { VzfpdfData } from './../../graphql/types'

export interface VZFLandingpageState {
    currentView: ViewType
    disabledSubmit: false
    vzfSummaryData: VzfpdfData
    vzfID: string
    B2B: boolean
}
export const useVZFLandingpage = (): VZFLandingpageState => {
    const { t } = useTranslation()
    const { appState, customizeJsData, generalState } = useSelector((appState: AppState) => {
        return {
            appState: appState,
            generalState: appState.generalState,
            customizeJsData: appState.generalState.customizeJsData,
        }
    })
    console.log(appState.generalState.pagesList)
    const { B2B } = useURLParams()
    const vzfSummaryData = generateVZFDataForText(appState.generalState.vzfID, appState, t, B2B, false, customizeJsData)

    return {
        currentView: generalState.currentView,
        disabledSubmit: false,
        vzfSummaryData,
        vzfID: generalState.vzfID,
        B2B,
    }
}
