import { LazyQueryResult, QueryLazyOptions, useLazyQuery } from '@apollo/client'
import AvailableAdditions from 'graphql/queries/AvailableAdditions'
import AvailableCitiesQuery from 'graphql/queries/AvailableCities'
import AvailableDistricts from 'graphql/queries/AvailableDistricts'
import AvailableHouseNumbersQuery from 'graphql/queries/AvailableHouseNumbers'
import AvailableStreetsQuery from 'graphql/queries/AvailableStreets'
import CheckVZF from 'graphql/queries/CheckVZF'
import {
    Customize,
    ProductCategory,
    Query,
    QueryCheckVzfArgs,
    SelectedProduct,
    SelectedProductCategory,
    VoucherData,
} from 'graphql/types'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, {
    AvailabilityCheckAction,
    AvailabilityCheckField,
} from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { GeneralState, LoadState, ViewType, initialPagesList } from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { checkIfErrorCategoryExists } from 'utils/testable/checkIfErrorCategoryExists'
import { updatePagesList } from 'utils/testable/updatePagesList'

interface AvailabilityReducerReturn {
    availableCities: LazyQueryResult<any, Record<string, any>>
    availableStreets: LazyQueryResult<any, Record<string, any>>
    availableDistricts: LazyQueryResult<any, Record<string, any>>
    availableAddition: LazyQueryResult<any, Record<string, any>>
    availableHouseNumbers: LazyQueryResult<any, Record<string, any>>
    cities: string[]
    currentView: ViewType
    customizeJsData?: Customize
    focusedFieldId: AvailabilityCheckField
    houseNumbers: string[]
    selectedCity: string
    selectedHouseNumber: string
    selectedStreet: string
    streets: string[]
    selectedDistrict: string
    districts: string[]
    selectedAddition: string
    additions: string[]
    zip: string
    vzf: string
    vzfNoResult: boolean
    vzfLoading: boolean
    setCities: (payload: string[]) => void
    setLoadState: (payload: LoadState) => void
    setHouseNumbers: (payload: string[]) => void
    setStreets: (payload: string[]) => void
    setDistricts: (payload: string[]) => void
    setAdditions: (payload: string[]) => void
    setSelectedCity: (payload: string) => void
    setSelectedHouseNumber: (payload: string) => void
    setSelectedStreet: (payload: string) => void
    setSelectedDistrict: (payload: string) => void
    setSelectedAddition: (payload: string) => void
    setFocusedField: (payload: AvailabilityCheckField) => void
    setZip: (payload: string) => void
    onChangeVZF: (payload: string) => void
    onClickCheckVZF: () => void
    resetCurrentPage: () => void
    getAvailableCities: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void
    getAvailableHouseNumbers: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void
    getAvailableStreets: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void
    getAvailableDistricts: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void
    getAvailableAdditions: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void
}

export const useAvailabilityCheckReducer: () => AvailabilityReducerReturn = () => {
    const dispatch =
        useDispatch<
            Dispatch<AvailabilityCheckActions | GeneralStateActions | ContactDataActions | PortabilityStateActions>
        >()

    const {
        cities,
        currentView,
        customizeJsData,
        errors,
        loadState,
        houseNumbers,
        streets,
        districts,
        additions,
        selectedCity,
        selectedHouseNumber,
        selectedStreet,
        selectedDistrict,
        selectedAddition,
        zip,
        vzf,
        vzfNoResult,
        focusedFieldId,
        noProduct,
        appState,
        orderProcessType,
    } = useSelector((appState: AppState) => {
        return {
            cities: appState.availabilityCheck.cities,
            currentView: appState.generalState.currentView,
            customizeJsData: appState.generalState.customizeJsData,
            orderProcessType: appState.generalState.orderProcessType,
            noProduct: appState.generalState.noProduct,
            errors: appState.generalState.errors,
            loadState: appState.generalState.loadState,
            houseNumbers: appState.availabilityCheck.houseNumbers,
            districts: appState.availabilityCheck.districts,
            additions: appState.availabilityCheck.additions,
            streets: appState.availabilityCheck.streets,
            selectedCity: appState.availabilityCheck.selectedCity,
            selectedHouseNumber: appState.availabilityCheck.selectedHouseNumber,
            selectedStreet: appState.availabilityCheck.selectedStreet,
            selectedDistrict: appState.availabilityCheck.selectedDistrict,
            selectedAddition: appState.availabilityCheck.selectedAddition,
            zip: appState.availabilityCheck.zip,
            vzf: appState.availabilityCheck.vzf,
            vzfNoResult: appState.availabilityCheck.vzfNoResult,
            focusedFieldId: appState.availabilityCheck.focusedFieldId,
            appState: appState,
        }
    })

    const history = useHistory()
    const { t } = useTranslation()
    const { B2B } = useURLParams()

    const setNoProducts = useCallback(
        (payload: boolean) => {
            dispatch({ type: GeneralStateAction.NO_PRODUCTS, payload })
        },
        [dispatch],
    )

    const clearLoadOrderErrors = useCallback(() => {
        dispatch({ type: GeneralStateAction.CLEAR_ERROR_CATEGORY, payload: 'loadOrder' })
    }, [dispatch])

    const clearNoPrudoctsErrors = useCallback(() => {
        dispatch({ type: GeneralStateAction.CLEAR_ERROR_CATEGORY, payload: ViewType.PRODUCT_CATEGORIES_SELECTION })
    }, [dispatch])

    const setVZFNoResult = useCallback(
        (payload: boolean) => {
            dispatch({ type: AvailabilityCheckAction.SET_VZF_NO_RESULT, payload })
        },
        [dispatch],
    )

    const setLoadState = useCallback(
        (payload: LoadState) => {
            dispatch({ type: GeneralStateAction.SET_LOAD_STATE, payload })
        },
        [dispatch],
    )
    const setCities = useCallback(
        (payload: string[]) => {
            dispatch({ type: AvailabilityCheckAction.SET_CITIES, payload })
        },
        [dispatch],
    )
    const setHouseNumbers = useCallback(
        (payload: string[]) => {
            dispatch({
                type: AvailabilityCheckAction.SET_HOUSE_NUMBERS,
                payload,
            })
        },
        [dispatch],
    )
    const setStreets = useCallback(
        (payload: string[]) => {
            dispatch({
                type: AvailabilityCheckAction.SET_STREETS,
                payload,
            })
        },
        [dispatch],
    )
    const setDistricts = useCallback(
        (payload: string[]) => {
            dispatch({
                type: AvailabilityCheckAction.SET_DISTRICT,
                payload,
            })
        },
        [dispatch],
    )
    const setAdditions = useCallback(
        (payload: string[]) => {
            dispatch({
                type: AvailabilityCheckAction.SET_ADDITION,
                payload,
            })
        },
        [dispatch],
    )
    const setZip = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== zip) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_ZIP,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )
    const setVZF = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            dispatch({
                type: AvailabilityCheckAction.SET_VZF,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )

    const setSelectedCity = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== selectedCity) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_SELECT_CITY,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )
    const setSelectedHouseNumber = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== selectedHouseNumber) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_SELECT_HOUSE_NUMBER,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )
    const setSelectedStreet = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== selectedStreet) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_SELECT_STREET,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )
    const setSelectedDistrict = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== selectedDistrict) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_SELECT_DISTRICT,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )
    const setSelectedAddition = useCallback(
        (payload: string) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            if (checkIfErrorCategoryExists(errors, 'productCategoriesSelection') && payload !== selectedAddition) {
                clearNoPrudoctsErrors()
            }
            setNoProducts(false)
            dispatch({
                type: AvailabilityCheckAction.SET_SELECT_ADDITION,
                payload,
            })
        },
        [dispatch, errors, clearLoadOrderErrors, clearNoPrudoctsErrors],
    )

    const resetCurrentPage = useCallback(() => {
        dispatch({ type: GeneralStateAction.RESET_GENERAL_STATE })
    }, [dispatch])

    const setFocusedField = useCallback(
        (payload: AvailabilityCheckField) => {
            dispatch({
                type: AvailabilityCheckAction.SET_FOCUSED_FIELD,
                payload,
            })
        },
        [dispatch],
    )

    const [getAvailableAdditions, availableAddition] = useLazyQuery(AvailableAdditions)
    const [getAvailableDistricts, availableDistricts] = useLazyQuery(AvailableDistricts)
    const [getAvailableHouseNumbers, availableHouseNumbers] = useLazyQuery(AvailableHouseNumbersQuery)
    const [getAvailableStreets, availableStreets] = useLazyQuery(AvailableStreetsQuery)
    const [getAvailableCities, availableCities] = useLazyQuery(AvailableCitiesQuery, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            if (data && data.availableCities) {
                if (data.availableCities.length === 0) {
                    setLoadState({
                        loading: false,
                        errors: 'noCitiesForZip',
                    })
                } else {
                    setLoadState({
                        loading: false,
                        errors: undefined,
                    })
                }
            }
        },
    })

    const setAvailabilityCheck = useCallback(
        (payload: AvailabilityCheckState): void => {
            dispatch({ type: AvailabilityCheckAction.SET_AVAILABILITY_STATE, payload })
        },
        [dispatch],
    )

    const setGeneralState = useCallback(
        (payload: GeneralState): void => {
            dispatch({ type: GeneralStateAction.SET_GENERAL_STATE, payload })
        },
        [dispatch],
    )

    const setPersonalMail = useCallback(
        (payload: string): void => {
            dispatch({ type: ContactDataAction.SET_PERSONAL_EMAIL, payload })
        },
        [dispatch],
    )

    const [checkVZF, checkVZFStatus] = useLazyQuery<Query>(CheckVZF, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.checkVZF && data.checkVZF.isValid === true && data.checkVZF.vzf) {
                const vzf = data.checkVZF.vzf

                setAvailabilityCheck({
                    ...appState.availabilityCheck,
                    selectedCity: vzf.city,
                    selectedStreet: vzf.street,
                    selectedHouseNumber: vzf.houseNumber,
                    zip: vzf.zipcode,
                })

                const availableProductCategories: ProductCategory[] = JSON.parse(vzf.selectedProductCategories)

                // translate to selected structure
                const selectedProductCategories: SelectedProductCategory[] = availableProductCategories.map(
                    (productCategory) => {
                        let selectedProduct: SelectedProduct | null = null
                        const productData = productCategory.products[0]
                        if (productData) {
                            selectedProduct = {
                                id: productData.id,
                                productTypes: productData.productTypes.map((productType) => ({
                                    id: productType.id,
                                    optionCategories: productType.category.map((optionCategory) => ({
                                        id: optionCategory.id,
                                        selectedOptions: optionCategory.options.map((option) => option.id),
                                    })),
                                })),
                            }
                        }

                        return { id: productCategory.id, selectedProduct }
                    },
                )

                const optionsMultipleSelect = new Map<string, number>()
                vzf.multipleSelectOptionList.forEach((entry) => {
                    optionsMultipleSelect.set(entry.key, entry.value)
                })

                const vouchers: VoucherData[] = []
                vzf.vouchers.forEach((voucher) => {
                    vouchers.push({
                        code: voucher.code,
                        articleNumber: voucher.articleNumber,
                        actions: voucher.actions,
                        id: voucher.id,
                        infoText: voucher.infoText,
                        name: voucher.name,
                        products: voucher.products,
                        type: voucher.type,
                        value: voucher.value,
                    })
                })

                setGeneralState({
                    ...appState.generalState,
                    vzfID: data.checkVZF.vzfID ?? '',
                    orderProcessType: vzf.customizeType ?? '',
                    clientData: vzf.clientData ?? undefined,
                    availableProductCategories: availableProductCategories,
                    selectedProductCategories: selectedProductCategories,
                    optionsMultipleSelect,
                    startOfDelivery: vzf.startOfDelivery ?? undefined,
                    startOfMarketing: vzf.startOfMarketing ?? undefined,
                    currentView: ViewType.VZF_LANDINGPAGE,
                    loadedFromVZF: true,
                    voucher: vouchers,
                    pagesList: updatePagesList(
                        true,
                        availableProductCategories,
                        selectedProductCategories,
                        [],
                        [...initialPagesList],
                        B2B,
                        appState.generalState.customizeJsData,
                    ),
                })
                setPersonalMail(vzf.email)
                if (!vzf.customizeType.endsWith('kupo') && vzf.customizeType != orderProcessType) {
                    let params = `?c=${vzf.customizeType}`
                    if (vzf.customizeType === '') {
                        params = ''
                    }
                    setTimeout(() => {
                        window.location.href =
                            window.location.protocol +
                            '//' +
                            window.location.host +
                            `/${params}#/${t(ViewType.VZF_LANDINGPAGE)}`
                    }, 0)
                } else {
                    if (customizeJsData && !customizeJsData.vzfConfiguration.disablePage) {
                        history.push(t(ViewType.VZF_LANDINGPAGE))
                    } else {
                        history.push(t(ViewType.CONTACT_DATA))
                    }
                }
            } else {
                setVZFNoResult(true)
            }
        },
    })

    const onClickCheckVZF = () => {
        const vars: QueryCheckVzfArgs = {
            vzf: vzf,
        }
        checkVZF({ variables: vars })
    }

    useEffect(() => {
        if ((zip.length === 5 && cities.length === 0) || noProduct) {
            setLoadState({
                loading: loadState.loading,
                errors: 'availabilityCheckHeaderError',
            })
        } else {
            setLoadState({
                loading: loadState.loading,
                errors: undefined,
            })
        }
    }, [zip, cities, setLoadState, loadState.loading, noProduct])

    return {
        availableCities,
        availableHouseNumbers,
        availableStreets,
        availableAddition,
        availableDistricts,
        cities,
        currentView,
        customizeJsData,
        focusedFieldId,
        houseNumbers,
        streets,
        districts,
        additions,
        zip,
        vzf,
        vzfNoResult,
        vzfLoading: checkVZFStatus.loading,
        selectedCity,
        selectedHouseNumber,
        selectedStreet,
        selectedDistrict,
        selectedAddition,
        setCities,
        setLoadState,
        setHouseNumbers,
        setSelectedCity,
        setSelectedHouseNumber,
        setSelectedStreet,
        setSelectedDistrict,
        setSelectedAddition,
        setFocusedField,
        setStreets,
        setDistricts,
        setAdditions,
        setZip,
        onChangeVZF: setVZF,
        onClickCheckVZF,
        getAvailableCities,
        getAvailableHouseNumbers,
        getAvailableStreets,
        getAvailableDistricts,
        getAvailableAdditions,
        resetCurrentPage,
    }
}
