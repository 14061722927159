import { combineReducers, createStore } from 'redux'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import AvailabilityCheckReducer from './AvailabilityCheck/AvailabilityCheck.reducer'
import BankDetailsReducer from './BankDetails/BankDetails.reducer'
import ContactDataReducer from './ContactData/ContactData.reducer'
import GeneralStateReducer, { GeneralState } from './GeneralState/GeneralState.reducer'
import PortabilityStateReducer from './PortabilityState/PortabilityState.reducer'

const SetTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: GeneralState) => {
        // convert mySet to an Array.
        return {
            ...inboundState,
            configuration: [...inboundState.configuration],
            optionsMultipleSelect: [...inboundState.optionsMultipleSelect],
            startOfContract: inboundState.startOfContract ? inboundState.startOfContract.toISOString() : null,
        }
    },
    // transform state being rehydrated
    (outboundState) => {
        // convert mySet back to a Set.
        let startOfContract: Date | null | undefined = null
        if (outboundState.startOfContract) {
            startOfContract = new Date(Date.parse(outboundState.startOfContract))
        }
        return {
            ...outboundState,
            configuration: new Map<string, string | string[]>(outboundState.configuration),
            optionsMultipleSelect: new Map<string, number>(outboundState.optionsMultipleSelect),
            desiredDate: new Date(),
            startOfContract: startOfContract,
        }
    },
    // define which reducers this transform gets called for.
    { whitelist: ['generalState'] },
)

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    transforms: [SetTransform],
}
const availabilityCheckPersistConfig = {
    key: 'availabilityCheck',
    storage: storageSession,
}
const bankDetailsPersistConfig = {
    key: 'bankDetails',
    storage: storageSession,
}
const contactDataPersistConfig = {
    key: 'contactData',
    storage: storageSession,
}
const generalStatePersistConfig = {
    key: 'generalState',
    storage: storageSession,
}
const portabilityStatePersistConfig = {
    key: 'portabilityState',
    storage: storageSession,
}

export const rootReducer = combineReducers({
    availabilityCheck: persistReducer(availabilityCheckPersistConfig, AvailabilityCheckReducer),
    bankDetails: persistReducer(bankDetailsPersistConfig, BankDetailsReducer),
    contactData: persistReducer(contactDataPersistConfig, ContactDataReducer),
    generalState: persistReducer(generalStatePersistConfig, GeneralStateReducer),
    portabilityState: persistReducer(portabilityStatePersistConfig, PortabilityStateReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = createStore(persistedReducer)
const persistor = persistStore(store)
export type AppState = ReturnType<typeof rootReducer>
export default { store, persistor }
