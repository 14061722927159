import { useLazyQuery, useMutation } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import CompleteOrderProgress from 'graphql/mutations/CompleteOrderProgress'
import SaveVZF from 'graphql/mutations/SaveVZF'
import AvailableProducts from 'graphql/queries/AvailableProducts'
import GenerateVZF from 'graphql/queries/GenerateVZF'
import ValidateManualVoucher from 'graphql/queries/ValidateManualVoucher'
import ValidateNonManualVoucher from 'graphql/queries/ValidateNonManualVoucher'
import getLoggedUser from 'graphql/queries/getLoggedUser'
import {
    AccountType,
    Client,
    Mutation,
    MutationSaveVzfArgs,
    ProductCategory,
    Query,
    SelectedProductCategory,
    VoucherData,
} from 'graphql/types'
import IBAN from 'iban'
import { cloneDeep } from 'lodash'
import React, { Dispatch, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import AvailabilityCheckActions, {
    AvailabilityCheckAction,
    AvailabilityCheckField,
} from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import { BankDetailsState, initialBankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import { ContactDataState, initialContactDataState } from 'store/ContactData/ContactData.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { PortabilityState, initialPortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, isSalesPartner, useUserInfo } from 'utils/UserInfoContext'
import { convertStateToOrderData } from 'utils/convertStateToOrderData'
import { stateToVZF } from 'utils/convertStateToVZF'
import { generateVZFDataForText } from 'utils/generateVZFDataForText'
import getEnvConfig from 'utils/getEnvConfig'
import { stateToPDFdata } from 'utils/stateToPDFData'
import { checkIfErrorCategoryExists } from 'utils/testable/checkIfErrorCategoryExists'
import { birthDateIsValid, mailIsValid } from 'utils/testable/functions'
import { getBankAccountApproval } from 'utils/testable/getBankAccountApproval'
import { pathToViewType } from 'utils/testable/pathToViewType'
import { executeTrackingCall } from 'utils/tracking'
import {
    AvailabilityCheckState,
    initialAvailabilityCheckState,
} from '../../store/AvailabilityCheck/AvailabilityCheck.reducer'
import GeneralStateActions, { GeneralStateAction } from '../../store/GeneralState/GeneralState.actions'
import {
    Error,
    GeneralState,
    LoadState,
    Page,
    ViewType,
    initialGeneralState,
} from '../../store/GeneralState/GeneralState.reducer'
import { AppState } from './../../store/store'

export type AvailableProductsResponse = {
    availableProducts: Array<ProductCategory>
}

export type AvailableProductsParams = {
    zipcode: string
    city: string
    street: string
    houseNumber: string
    district?: string
    addition?: string
    B2B: boolean
    loadTestData?: boolean
    productGroups: string[]
}

interface ViewWrapperReducerReturn {
    availabilityCheck: AvailabilityCheckState
    generalState: GeneralState
    bankDetails: BankDetailsState
    loadState: LoadState
    minHeight: string
    nextPageType: ViewType
    onBack: () => void
    onSubmit: () => void
    closeVPPOrderProcessInfo: () => void
    errors: Error[]
    B2B: boolean
    displayVPPProcesInfo: boolean
    salesPartner: boolean
    vzfID: string
    currentPageData: Page
    infoBoxURL: string
}

export const useViewWrapperReducer: () => ViewWrapperReducerReturn = () => {
    const { t } = useTranslation()
    const [userInfo, setUserInfo] = useUserInfo()
    const { keycloak } = useKeycloak()
    const { B2B, loadTestData, preselectedProduct } = useURLParams()
    const envConfig = getEnvConfig()
    const isVPPProcess = envConfig.VPPUrlPath === window.location.pathname && window.location.pathname !== '/'

    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | BankDetailsActions
                | ContactDataActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()
    const availabilityDispatch = useDispatch<Dispatch<AvailabilityCheckActions>>()

    const history = useHistory()
    const {
        availabilityCheck,
        accountType,
        currentPage,
        customizeJsData,
        personalEmail,
        personalBirthDate,
        iban,
        inLineErrors,
        redeemManualVoucher,
        voucherCode,
        generalState,
        bankDetails,
        loadState,
        pagesList,
        selectedCity,
        selectedHouseNumber,
        phoneOptions,
        selectedStreet,
        selectedZipCode,
        errors,
        vzfID,
        appState,
        displayVPPProcesInfo,
        availableProductCategories,
    } = useSelector((appState: AppState) => {
        return {
            availabilityCheck: appState.availabilityCheck,
            currentPage: appState.generalState.currentPage,
            availableProductCategories: appState.generalState.availableProductCategories,
            customizeJsData: appState.generalState.customizeJsData,
            personalEmail: appState.contactData.personalEmail,
            personalBirthDate: appState.contactData.personalBirthDate,
            inLineErrors: appState.generalState.inLineErrors,
            redeemManualVoucher: appState.generalState.redeemManualVoucher,
            voucherCode: appState.generalState.voucherCode,
            phoneOptions: appState.portabilityState.phoneOptions,
            generalState: appState.generalState,
            bankDetails: appState.bankDetails,
            iban: appState.bankDetails.iban,
            accountType: appState.bankDetails.accountType,
            loadState: appState.generalState.loadState,
            pagesList: appState.generalState.pagesList,
            selectedCity: appState.availabilityCheck.selectedCity,
            selectedHouseNumber: appState.availabilityCheck.selectedHouseNumber,
            selectedStreet: appState.availabilityCheck.selectedStreet,
            selectedZipCode: appState.availabilityCheck.zip,
            errors: appState.generalState.errors,
            vzfID: appState.generalState.vzfID,
            displayVPPProcesInfo: appState.generalState.displayVPPProcesInfo,
            appState: appState,
        }
    })
    const salesPartner = isSalesPartner(userInfo ? userInfo.roles : [], customizeJsData)
    const infoBoxURL = salesPartner ? `${envConfig.VPPUrlPath}` : '/'

    let nextPage = ''
    let nextPageType: ViewType = ViewType.AVAILABILITY_CHECK
    const currentPageData = pagesList[currentPage]
    if (pagesList != undefined && pagesList.length > currentPage + 1) {
        nextPage = pagesList[currentPage + 1].path
        nextPageType = pathToViewType(nextPage)
    }

    const [copy, setCopy] = useState<SelectedProductCategory[]>(cloneDeep(generalState.selectedProductCategories))

    const minHeight = useMemo(
        () =>
            generalState.currentView === ViewType.AVAILABILITY_CHECK ||
            generalState.currentView === ViewType.PRODUCT_CATEGORIES_SELECTION ||
            generalState.currentView === ViewType.PRODUCT_TYPE_SELECTION ||
            generalState.currentView === ViewType.PRODUCT_SELECTION ||
            generalState.currentView === ViewType.OPTIONS_CONFIGURATION ||
            generalState.currentView === ViewType.PRODUCT_CROSS_SELLING ||
            generalState.currentView === ViewType.VZF_VIEW ||
            generalState.currentView === ViewType.VZF_LANDINGPAGE ||
            loadState.loading ||
            B2B
                ? 'calc(100vh - 194px)'
                : 'calc(100vh - 244px)',
        [generalState.currentView, loadState.loading],
    )

    const location = useLocation()

    useEffect(() => {
        if (appState.availabilityCheck.zip === '' && location.pathname !== '/Verfügbarkeitsprüfung') {
            history.replace('/')
        }

        const newCurrentPage =
            location.pathname === '/' + t(ViewType.AVAILABILITY_CHECK)
                ? 0
                : pagesList.findIndex((p) => p.path === location.pathname)
        if (newCurrentPage !== -1) {
            setCurrentPage(newCurrentPage)
            executeTrackingCall(pagesList[newCurrentPage].path)
            if (location.pathname === '/Verfügbarkeitsprüfung') {
                resetState()
            }
        }
    }, [history, location.pathname])

    const setLoadState = useCallback(
        (payload: LoadState) => {
            dispatch({ type: GeneralStateAction.SET_LOAD_STATE, payload })
        },
        [dispatch],
    )

    const setVPPProcessInfo = useCallback(() => {
        dispatch({ type: GeneralStateAction.SET_VPP_PROCESS_INFO })
    }, [dispatch])

    const setCurrentPage = useCallback(
        (payload: number) => {
            dispatch({ type: GeneralStateAction.SET_CURRENT_PAGE, payload })
        },
        [dispatch],
    )

    const setAvailabilityCheck = useCallback(
        (payload: AvailabilityCheckState): void => {
            dispatch({ type: AvailabilityCheckAction.SET_AVAILABILITY_STATE, payload })
        },
        [dispatch],
    )

    const setGeneralState = useCallback(
        (payload: GeneralState): void => {
            dispatch({ type: GeneralStateAction.SET_GENERAL_STATE, payload })
        },
        [dispatch],
    )

    const setOnBack = useCallback(
        (payload: boolean): void => {
            dispatch({ type: GeneralStateAction.SET_ON_BACK, payload })
        },
        [dispatch],
    )

    const setContactDataState = useCallback(
        (payload: ContactDataState): void => {
            dispatch({ type: ContactDataAction.SET_CONTACT_DATA_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const setBankDetailsState = useCallback(
        (payload: BankDetailsState): void => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_STATE, payload })
        },
        [dispatch],
    )

    const setPortabilityState = useCallback(
        (payload: PortabilityState): void => {
            dispatch({ type: PortabilityStateAction.SET_PORTABILITY_STATE, payload })
        },
        [dispatch],
    )

    const setGeneralStatePartial = useCallback(
        (payload: Partial<GeneralState>) => {
            dispatch({ type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const setNoProducts = useCallback(
        (payload: boolean) => {
            dispatch({ type: GeneralStateAction.NO_PRODUCTS, payload })
        },
        [dispatch],
    )

    const setAvaiableProductsCategory = useCallback(
        (payload: { list: ProductCategory[]; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SET_AVAILABLE_PRODUCT_CATEGORIES, payload })
        },
        [dispatch],
    )

    const clearLoadOrderErrors = useCallback(() => {
        dispatch({ type: GeneralStateAction.CLEAR_ERROR_CATEGORY, payload: 'loadOrder' })
    }, [dispatch])

    const setInLineErrors = useCallback(
        (payload: string[]) => {
            dispatch({ type: GeneralStateAction.SET_INLINE_ERRORS, payload })
        },
        [dispatch],
    )

    const setBankDetailsPartial = useCallback(
        (payload: Partial<BankDetailsState>) => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_PARTIAL, payload })
        },
        [dispatch],
    )

    const addVouchers = useCallback(
        (payload: VoucherData[]) => {
            dispatch({ type: GeneralStateAction.ADD_VOUCHERS, payload })
        },
        [dispatch],
    )

    const removeAllVouchers = useCallback(() => {
        dispatch({ type: GeneralStateAction.REMOVE_ALL_VOUCHERS })
    }, [dispatch])

    const setSelectedProductCategories = useCallback(
        (payload: { id: string; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT_CATEGORY, payload })
        },
        [dispatch],
    )

    const setClientData = useCallback(
        (payload: Client | undefined) => {
            dispatch({ type: GeneralStateAction.SET_CLIENT_DATA, payload })
        },
        [dispatch],
    )

    const setContractStartDate = useCallback(
        (payload: Date | null) => {
            dispatch({ type: GeneralStateAction.SET_CONTRACT_START_DATE, payload })
        },
        [dispatch],
    )
    const setSupplyStart = useCallback(
        (payload: number | null) => {
            dispatch({ type: GeneralStateAction.SET_SUPPLY_START, payload })
        },
        [dispatch],
    )

    const setOrderID = useCallback(
        (payload: string) => {
            dispatch({ type: GeneralStateAction.SET_ORDERID, payload })
        },
        [dispatch],
    )

    const setSelectedProduct = useCallback(
        (payload: { productCategoryId: string; productId: string; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT, payload })
        },
        [dispatch],
    )

    const resetState = () => {
        dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: ContactDataAction.SET_TO_INIT_STATE })
        dispatch({ type: BankDetailsAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: GeneralStateAction.SET_TO_INIT_STATE, payload: { B2B } })

        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
    }

    const getAllProductIDs = (productCategories: SelectedProductCategory[]): string[] => {
        const ids: string[] = []

        productCategories.forEach((prodCat) => {
            const availableCategory = availableProductCategories.find((p) => p.id === prodCat.id)
            if (prodCat.selectedProduct) {
                ids.push(prodCat.selectedProduct.id)
                const avaiableProduct = availableCategory?.products.find((p) => p.id === prodCat.selectedProduct?.id)
                avaiableProduct?.fees.forEach((fee) => {
                    ids.push(fee.id)
                })
                prodCat.selectedProduct.productTypes.forEach((prodType) => {
                    ids.push(prodType.id)
                    const avaiableProductType = avaiableProduct?.productTypes.find((p) => p.id === prodType.id)

                    prodType.optionCategories.forEach((optCat) => {
                        const avaiableOptionCategory = avaiableProductType?.category.find((p) => p.id === optCat.id)
                        optCat.selectedOptions.forEach((opt) => {
                            const avaiableOption = avaiableOptionCategory?.options.find((p) => p.id === opt)
                            avaiableOption?.fees.forEach((fee) => {
                                ids.push(fee.id)
                            })
                            ids.push(opt)
                        })
                    })
                })
            }
        })
        return ids
    }

    const [getAvailableProducts] = useLazyQuery<Query>(AvailableProducts, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data && data.availableProducts) {
                if (data.availableProducts.ProductCategories.length === 0) {
                    setLoadState({
                        ...loadState,
                        loading: false,
                        errors: 'availabilityCheckHeaderError',
                    })
                    setNoProducts(true)
                } else {
                    setLoadState({
                        ...loadState,
                        loading: false,
                    })
                    setAvaiableProductsCategory({ list: cloneDeep(data.availableProducts.ProductCategories), B2B })
                    if (
                        data.availableProducts.startOfDelivery !== null &&
                        data.availableProducts.startOfMarketing !== null
                    ) {
                        setGeneralStatePartial({
                            startOfMarketing: data.availableProducts.startOfMarketing,
                            startOfDelivery: data.availableProducts.startOfDelivery,
                        })
                    }

                    if (data.availableProducts.ClientData !== null) {
                        setClientData(data.availableProducts.ClientData)
                        if (data.availableProducts) {
                            const startOfContract = data.availableProducts.startOfContract
                            setSupplyStart(data.availableProducts.supplyStart ?? null)
                            if (
                                startOfContract !== undefined &&
                                startOfContract !== null &&
                                !data.availableProducts.ClientData?.useDesiredDate
                            ) {
                                const contractStartDate = new Date(startOfContract)
                                setContractStartDate(contractStartDate)
                            } else {
                                setContractStartDate(null)
                            }
                        } else {
                            setContractStartDate(null)
                        }
                    }

                    //CHECK IF THE PRESELECTED PRODUCT IS AVAILABLE IN PARAM
                    let isProductAvailable = false
                    if (preselectedProduct) {
                        for (const category of data.availableProducts.ProductCategories) {
                            const foundProduct = category.products.find((product) => product.id === preselectedProduct)
                            if (foundProduct) {
                                isProductAvailable = true
                                setSelectedProductCategories({ id: category.id, B2B })
                                setSelectedProduct({
                                    productCategoryId: category.id,
                                    productId: foundProduct.id,
                                    B2B: B2B,
                                })
                                //TODO: we have to implement that we always get the update page list back
                                if (customizeJsData?.productCategoriesConfiguration.enablePage) {
                                    history.push(pagesList[currentPage + 2].path)
                                } else {
                                    history.push(pagesList[currentPage + 1].path)
                                }
                            }
                        }
                    }

                    if (!isProductAvailable) {
                        if (customizeJsData?.productCategoriesConfiguration.enablePage) {
                            if (data.availableProducts.ProductCategories.length === 1) {
                                setSelectedProductCategories({
                                    id: data.availableProducts.ProductCategories[0].id,
                                    B2B,
                                })
                                history.push('/Produktauswahl/' + data.availableProducts.ProductCategories[0].id)
                            } else {
                                history.push(pagesList[currentPage + 1].path)
                            }
                        } else {
                            setSelectedProductCategories({ id: data.availableProducts.ProductCategories[0].id, B2B })
                            history.push('/Produktauswahl/' + data.availableProducts.ProductCategories[0].id)
                        }
                    }
                }
            }
        },
    })

    const [completeOrderProgress] = useMutation<Mutation>(CompleteOrderProgress, {
        onCompleted: (data) => {
            if (data.completeOrderProgress && data.completeOrderProgress.success === true) {
                //TODO: not here how should the errors look like also clear useState?
                const temp = cloneDeep(data.completeOrderProgress.orderNumber)
                resetState()
                setOrderID(temp)
                history.push(pagesList[currentPage + 1].path)
            } else {
                // TODO: implement error handling
            }
            setLoadState({
                ...loadState,
                loading: false,
            })
        },
        onError: (error) => console.error({ errors: [error.message], errorsTitle: error.name }),
    })

    const [getLoggedUserQuery] = useLazyQuery<Query>(getLoggedUser, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            if (data && data.loadLoggedUser) {
                setUserInfo({
                    number: data.loadLoggedUser.customerNumber,
                    name: data.loadLoggedUser.customerNumber,
                    roles: data.loadLoggedUser.roles,
                })
            }
        },
    })

    const [validateNonManualVoucher] = useLazyQuery<Query>(ValidateNonManualVoucher, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            if (data && data.validateNonManualVoucher) {
                addVouchers(data.validateNonManualVoucher)
            }
            setGeneralStatePartial({
                vzfID: '',
            })
            if (customizeJsData && !customizeJsData.voucherConfiguration.enableManualPage) {
                setTimeout(() => {
                    onSaveVZFData()
                }, 500)
            } else {
                history.push(pagesList[currentPage + 1].path)
            }
        },
    })

    const [validateManualVoucher] = useLazyQuery<Query>(ValidateManualVoucher, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            if (data && data.validateManualVoucher) {
                const vouchers = cloneDeep(generalState.voucher)
                if (vouchers.find((voucher) => voucher.type === 'manual') !== undefined) {
                    const index = vouchers.findIndex((voucher) => voucher.type === 'manual')
                    vouchers.splice(index, 1)
                    removeAllVouchers()
                    addVouchers(vouchers)
                    addVouchers([data.validateManualVoucher])
                } else {
                    addVouchers([data.validateManualVoucher])
                }

                setGeneralStatePartial({
                    vzfID: '',
                })

                setTimeout(() => {
                    onSaveVZFData()
                }, 1000)
            } else {
                const vouchers = cloneDeep(generalState.voucher)
                if (vouchers.find((voucher) => voucher.type === 'manual') !== undefined) {
                    const index = vouchers.findIndex((voucher) => voucher.type === 'manual')
                    vouchers.splice(index, 1)
                    removeAllVouchers()
                    addVouchers(vouchers)
                }
                const newErrors: string[] = [...inLineErrors]
                if (!newErrors.includes('voucherCode')) {
                    setInLineErrors([...newErrors, 'voucherCode'])
                }
                setGeneralStatePartial({
                    vzfID: '',
                })
            }
        },
    })

    const [saveVzf] = useMutation<Mutation>(SaveVZF, {
        onCompleted: (data) => {
            if (data && data.saveVZF.status === 'success') {
                history.push(pagesList[currentPage + 1].path)
            }
        },
    })

    const [generateVZFID] = useLazyQuery<Query>(GenerateVZF, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.generateVZFID) {
                dispatch({
                    type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL,
                    payload: { vzfID: data.generateVZFID },
                })
                onVZFSave(data.generateVZFID)
            }
        },
    })

    const onVZFSave = (generateVZFID: string): void => {
        const vzfSummaryData = generateVZFDataForText(generateVZFID, appState, t, B2B, false, customizeJsData)
        const vzfData = stateToVZF(appState)
        const variables: MutationSaveVzfArgs = {
            vzfID: generateVZFID,
            input: {
                additionalInfo: vzfData.additionalInfo,
                city: vzfData.city,
                houseNumber: vzfData.houseNumber,
                startOfMarketing: generalState.startOfMarketing,
                startOfDelivery: generalState.startOfDelivery,
                selectedProductCategories: vzfData.selectedProductCategories,
                multipleSelectOptionList: vzfData.multipleSelectOptionList,
                street: vzfData.street,
                zipcode: vzfData.zipcode,
                email: vzfData.email,
                path: '',
                customizeType: vzfData.customizeType,
                vouchers: vzfData.vouchers,
            },
            pdfData: vzfSummaryData,
            b2b: B2B,
        }
        if (
            appState.generalState.customizeJsData &&
            appState.generalState.clientData &&
            appState.generalState.customizeJsData.globalConfiguration.enableLoadingClient
        ) {
            variables.clientID = appState.generalState.clientData.clientID
        }
        saveVzf({ variables })
    }

    const onSaveVZFData = (): void => {
        if (appState.generalState.vzfID === '') {
            generateVZFID()
        } else {
            history.push(pagesList[currentPage + 1].path)
        }
    }

    const validateContactData = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...inLineErrors]
        if (!mailIsValid(personalEmail)) {
            if (!inLineErrors.includes('email')) {
                newErrors.push('email')
            }
            returnBoolean = false
        }

        switch (birthDateIsValid(personalBirthDate)) {
            case 'invalid':
                newErrors.push('birthDate_invalid')
                returnBoolean = false
                break
            case 'tooYoung':
                newErrors.push('birthDate_min')
                returnBoolean = false
                break
            case 'tooOld':
                newErrors.push('birthDate_max')
                returnBoolean = false
                break
        }

        setInLineErrors(newErrors)
        return returnBoolean
    }
    const validateInstallationDetails = (): boolean => {
        const newErrors: string[] = []
        let isValid = true
        if (customizeJsData) {
            if (customizeJsData.installationDetailsConfiguration.appartmentData) {
                const floorRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.floorInformationRequired
                if (floorRequired && appState.generalState.installationDetails.floorInformation === '') {
                    // ETAGE
                    isValid = false
                    newErrors.push('floorInformationRequired')
                }

                const flatPositionRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.flatPositionRequired
                if (flatPositionRequired && appState.generalState.installationDetails.flatPosition === '') {
                    // LAGE Wohnung
                    isValid = false
                    newErrors.push('flatPositionRequired')
                }
                const flatNumberRequired =
                    customizeJsData.installationDetailsConfiguration.appartmentData.flatNumberRequired
                if (flatNumberRequired && appState.generalState.installationDetails.flatNumber === '') {
                    // LAGE Wohnung
                    isValid = false
                    newErrors.push('flatNumberRequired')
                }
            }
        }
        setInLineErrors(newErrors)
        return isValid
    }
    const validateBankData = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...inLineErrors]
        if (!IBAN.isValid(iban)) {
            if (!inLineErrors.includes('iban')) {
                newErrors.push('iban')
            }
            returnBoolean = false
        } else {
            const errorIndex = newErrors.indexOf('iban')
            if (errorIndex !== -1) {
                newErrors.splice(errorIndex, 1)
            }
        }
        setInLineErrors(newErrors)
        setBankDetailsPartial({ isValidating: true })
        return returnBoolean
    }
    const validatePortabilityDetails = (): boolean => {
        let returnBoolean = true
        const newErrors: string[] = [...inLineErrors]
        phoneOptions.forEach((op, i) => {
            if (i !== 0) {
                if (op.areaCode !== phoneOptions[0].areaCode) {
                    newErrors.push('areaCode' + i)
                    returnBoolean = false
                } else {
                    const errorIndex = newErrors.indexOf('areaCode' + i)
                    if (errorIndex !== -1) {
                        newErrors.splice(errorIndex, 1)
                    }
                }
            }
        })
        setInLineErrors(newErrors)
        return returnBoolean
    }

    const executeValidateNonManualVoucher = () => {
        const products = getAllProductIDs(generalState.selectedProductCategories)
        validateNonManualVoucher({
            variables: {
                input: {
                    zipcode: availabilityCheck.zip,
                    city: availabilityCheck.selectedCity,
                    street: availabilityCheck.selectedStreet,
                    housenumber: availabilityCheck.selectedHouseNumber,
                    products,
                },
            },
        })
    }

    const setZip = React.useCallback(
        (zip: string) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_ZIP, payload: zip })
        },
        [availabilityDispatch],
    )
    const setFocus = React.useCallback(
        (focusId: AvailabilityCheckField) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_FOCUSED_FIELD, payload: focusId })
        },
        [availabilityDispatch],
    )

    const onClickReturn = (): void => {
        if (
            customizeJsData?.globalConfiguration.logoClickWebsite &&
            customizeJsData?.globalConfiguration.logoClickWebsite !== undefined
        )
            window.open(customizeJsData?.globalConfiguration.logoClickWebsite, '_blank')
        else {
            setZip('')
            setFocus(AvailabilityCheckField.ZIP)
            resetState()
            history.push('/')
        }
    }

    const onSubmit = (): void => {
        if (checkIfErrorCategoryExists(generalState.errors, 'loadOrder')) {
            clearLoadOrderErrors()
        }

        //get the next page from the page list
        if (pagesList != undefined && pagesList.length >= currentPage + 1) {
            nextPage = pagesList[currentPage + 1].path
            nextPageType = pathToViewType(nextPage)
        }

        if (
            nextPageType == ViewType.MANUAL_VOUCHER_REDEMPTION &&
            customizeJsData &&
            customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            executeValidateNonManualVoucher()
        } else if (
            nextPageType == ViewType.VZF_VIEW &&
            customizeJsData &&
            !customizeJsData.voucherConfiguration.enableManualPage &&
            customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            executeValidateNonManualVoucher()
        } else if (
            nextPageType == ViewType.VZF_VIEW &&
            customizeJsData &&
            customizeJsData.voucherConfiguration.enableManualPage &&
            redeemManualVoucher === false
        ) {
            onSaveVZFData()
        } else if (
            nextPageType == ViewType.VZF_VIEW &&
            customizeJsData &&
            !customizeJsData.voucherConfiguration.enableManualPage &&
            !customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            onSaveVZFData()
        } else if (
            nextPageType == ViewType.CONTACT_DATA &&
            customizeJsData &&
            customizeJsData.vzfConfiguration.disablePage &&
            !customizeJsData.voucherConfiguration.enableManualPage &&
            customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            executeValidateNonManualVoucher()
        } else if (
            nextPageType == ViewType.CONTACT_DATA &&
            customizeJsData &&
            customizeJsData.vzfConfiguration.disablePage &&
            customizeJsData.voucherConfiguration.enableManualPage &&
            redeemManualVoucher === false
        ) {
            onSaveVZFData()
        } else if (
            nextPageType == ViewType.CONTACT_DATA &&
            customizeJsData &&
            customizeJsData.vzfConfiguration.disablePage &&
            !customizeJsData.voucherConfiguration.enableManualPage &&
            !customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            onSaveVZFData()
        } else if (currentPage === 0) {
            const variables: AvailableProductsParams = {
                zipcode: selectedZipCode,
                city: selectedCity,
                street: selectedStreet,
                houseNumber: selectedHouseNumber,
                B2B: B2B,
                productGroups: [],
            }
            if (availabilityCheck.selectedDistrict) {
                variables.district = availabilityCheck.selectedDistrict
            }

            if (availabilityCheck.selectedAddition) {
                variables.addition = availabilityCheck.selectedAddition
            }
            if (loadTestData) {
                variables.loadTestData = true
            }
            if (customizeJsData && customizeJsData.globalConfiguration.productGroups) {
                variables.productGroups = customizeJsData.globalConfiguration.productGroups
            }
            setLoadState({
                ...loadState,
                loading: true,
            })
            getAvailableProducts({ variables })
        } else if (generalState.currentView === ViewType.CONTACT_DATA) {
            if (validateContactData()) {
                history.push(pagesList[currentPage + 1].path)
            }
        } else if (generalState.currentView === ViewType.PORTABILITY_DETAILS) {
            if (validatePortabilityDetails()) {
                history.push(pagesList[currentPage + 1].path)
            }
        } else if (generalState.currentView === ViewType.INSTALLATION_DETAILS) {
            if (validateInstallationDetails()) {
                history.push(pagesList[currentPage + 1].path)
            }
        } else if (generalState.currentView === ViewType.BANK_DETAILS) {
            if (!bankDetails.differentAccountHolder) {
                setBankDetailsPartial({
                    ...bankDetails,
                    accountHolderData: {
                        city: '',
                        company: '',
                        companyLegalForm: '',
                        houseNumber: '',
                        lastName: '',
                        name: '',
                        salutation: '',
                        street: '',
                        title: '',
                        zip: '',
                        additionalInfoAddress: undefined,
                    },
                })
            }
            if (accountType === AccountType.IBAN) {
                if (validateBankData()) {
                    history.push(pagesList[currentPage + 1].path)
                }
            } else {
                setInLineErrors([])
                history.push(pagesList[currentPage + 1].path)
            }
        } else if (generalState.currentView === ViewType.MANUAL_VOUCHER_REDEMPTION && redeemManualVoucher === true) {
            const products = getAllProductIDs(generalState.selectedProductCategories)
            validateManualVoucher({
                variables: {
                    input: {
                        code: voucherCode,
                        zipcode: availabilityCheck.zip,
                        city: availabilityCheck.selectedCity,
                        street: availabilityCheck.selectedStreet,
                        housenumber: availabilityCheck.selectedHouseNumber,
                        products,
                    },
                },
            })
        } else if (generalState.currentView === ViewType.ORDER_OVERVIEW) {
            setLoadState({
                ...loadState,
                loading: true,
            })
            const orderData = convertStateToOrderData(appState, B2B)
            const pdfData = stateToPDFdata(appState, B2B, t, customizeJsData)

            if (customizeJsData) {
                const productsList: string[] = []
                const productsDataSheetList: string[] = []
                generalState.selectedProductCategories.forEach((p) => {
                    for (let i = 0; i < generalState.availableProductCategories.length; i++) {
                        const selectedProductID = p.selectedProduct ? p.selectedProduct.id : ''
                        if (generalState.availableProductCategories[i].id === p.id) {
                            generalState.availableProductCategories[i].products.forEach((availableProduct) => {
                                if (availableProduct.id === selectedProductID) {
                                    productsList.push(availableProduct.title)
                                    if (availableProduct.dataSheet) {
                                        productsDataSheetList.push(
                                            '<a target="_blank" href="' +
                                                availableProduct.dataSheet +
                                                '" >' +
                                                availableProduct.title +
                                                '</a>',
                                        )
                                    }
                                }
                            })
                        }
                    }
                })
                const productsInformationSheetsText = productsDataSheetList.join(', ')
                const productsText = productsList.join(', ')

                for (const opt of customizeJsData.optList) {
                    if (opt === 'bankConsent') {
                        const labels = getBankAccountApproval(
                            orderData.bankDetails.accountType,
                            orderData.bankDetails.differentAccountHolder,
                        )
                        orderData.opt.push({
                            key: opt,
                            value: t(getRoleAwareTranslationKey(userInfo, customizeJsData, labels.consentLabel)),
                        })
                        if (
                            customizeJsData.bankDetails.useSecondConsentIBAN === true &&
                            orderData.bankDetails.accountType === AccountType.IBAN
                        ) {
                            orderData.opt.push({
                                key: 'bankConsent2',
                                value: t(getRoleAwareTranslationKey(userInfo, customizeJsData, labels.consentLabel2)),
                            })
                        }
                    } else {
                        const option = generalState.configuration.get(opt)
                        if (typeof option === 'string' && option === 'true') {
                            let label = t(
                                getRoleAwareTranslationKey(
                                    userInfo,
                                    customizeJsData,
                                    'orderOverviewStrings.checkboxes.' + opt,
                                ),
                                { product: productsText, dataSheets: productsInformationSheetsText },
                            )
                            const labelB2B = 'orderOverviewStrings.checkboxes.' + opt + 'B2B'
                            if (B2B === true && labelB2B !== t(labelB2B)) {
                                label = t(labelB2B)
                            }

                            orderData.opt.push({
                                key: opt,
                                value: label,
                            })
                        }
                    }
                }
            }
            if (userInfo) {
                orderData.distributor = userInfo.number
                console.log(userInfo)
                if (orderData.salesPartner == undefined) {
                    orderData.salesPartner = userInfo.name
                }
            }
            if (orderData.contractData.installationDetails) {
                orderData.contractData.installationDetails.junctionBox = t(
                    'installationDetailsStrings.junctionBox.' + orderData.contractData.installationDetails.junctionBox,
                )
                if (orderData.contractData.installationDetails.houseConnection.trim().length > 0) {
                    orderData.contractData.installationDetails.houseConnection = t(
                        'installationDetailsStrings.houseConnection.' +
                            orderData.contractData.installationDetails.houseConnection,
                    )
                }
                if (orderData.contractData.installationDetails.opticFiber.trim().length > 0) {
                    orderData.contractData.installationDetails.opticFiber = t(
                        'installationDetailsStrings.opticFiber.' +
                            orderData.contractData.installationDetails.opticFiber,
                    )
                }
            }
            const vzfSummaryData = generateVZFDataForText(
                appState.generalState.vzfID,
                appState,
                t,
                B2B,
                false,
                customizeJsData,
            )
            completeOrderProgress({
                variables: { order: orderData, pdf: pdfData, vzfSummaryData: vzfSummaryData },
            })
        } else {
            history.push(pagesList[currentPage + 1].path)
        }
        if (JSON.stringify(copy) !== JSON.stringify(generalState.selectedProductCategories)) {
            setGeneralStatePartial({
                vzfID: '',
            })
        }
    }

    const onBack = (): void => {
        if (checkIfErrorCategoryExists(generalState.errors, 'loadOrder')) {
            clearLoadOrderErrors()
        }
        if (
            pathToViewType(pagesList[currentPage].path) === ViewType.MANUAL_VOUCHER_REDEMPTION &&
            customizeJsData &&
            customizeJsData.voucherConfiguration.enableAutomatic
        ) {
            removeAllVouchers()
        }
        if (
            pathToViewType(pagesList[currentPage].path) === ViewType.CONTACT_DATA &&
            customizeJsData &&
            customizeJsData.voucherConfiguration.enableAutomatic &&
            !customizeJsData.voucherConfiguration.enableManualPage
        ) {
            removeAllVouchers()
        }
        if (pathToViewType(pagesList[currentPage].path) === ViewType.VZF_VIEW) {
            setCopy(cloneDeep(generalState.selectedProductCategories))
            setOnBack(true)
        }
        if (
            pathToViewType(pagesList[currentPage].path) === ViewType.CONTACT_DATA &&
            customizeJsData &&
            customizeJsData.vzfConfiguration.disablePage
        ) {
            setCopy(cloneDeep(generalState.selectedProductCategories))
        }
        if (pathToViewType(pagesList[currentPage].path) === ViewType.PRODUCT_CATEGORIES_SELECTION) {
            setOnBack(true)
        }
        if (pathToViewType(pagesList[currentPage].path) === ViewType.VZF_LANDINGPAGE) {
            setGeneralState({ ...initialGeneralState, customizeJsData: generalState.customizeJsData })
            setAvailabilityCheck(initialAvailabilityCheckState)
            setContactDataState(initialContactDataState)
            setBankDetailsState(initialBankDetailsState)
            setPortabilityState(initialPortabilityState)
            setLoadState({
                ...loadState,
                loading: false,
            })
            history.push(pagesList[0].path)
        } else {
            if (pathToViewType(pagesList[currentPage - 1].path) == ViewType.AVAILABILITY_CHECK) {
                if (customizeJsData && customizeJsData.productCategoriesConfiguration.enablePage) {
                    onClickReturn()
                }

                setClientData(undefined)
            }
            if (
                customizeJsData &&
                !customizeJsData.productCategoriesConfiguration.enablePage &&
                pathToViewType(pagesList[currentPage - 1].path) === ViewType.PRODUCT_CATEGORIES_SELECTION
            ) {
                history.push(pagesList[0].path)
            } else {
                history.push(pagesList[currentPage - 1].path)
            }
        }
    }

    const closeVPPOrderProcessInfo = (): void => {
        setVPPProcessInfo()
        if (!salesPartner) {
            keycloak.login()
        }
    }

    useEffect(() => {
        if (generalState.currentView === ViewType.AVAILABILITY_CHECK) {
            setGeneralStatePartial({
                vzfID: '',
            })
        }
    }, [generalState.currentView])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        setInLineErrors([])
    }, [history.location.pathname])

    useEffect(() => {
        if (keycloak.authenticated && keycloak.tokenParsed) {
            const id: string | undefined = keycloak.tokenParsed?.sub
            if (id)
                getLoggedUserQuery({
                    variables: {
                        id,
                    },
                })
        }
    }, [keycloak.authenticated])

    useEffect(() => {
        if (!isVPPProcess) {
            setVPPProcessInfo()
        }
    }, [isVPPProcess])

    return {
        availabilityCheck,
        generalState,
        bankDetails,
        loadState,
        minHeight,
        onSubmit,
        onBack,
        errors,
        B2B,
        nextPageType,
        vzfID,
        displayVPPProcesInfo,
        salesPartner,
        closeVPPOrderProcessInfo,
        currentPageData,
        infoBoxURL,
    }
}
