import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import { AccountType, Customize } from 'graphql/types'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Error, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { salutationIsCompany } from 'utils/testable/functions'
import { getBankAccountApproval } from 'utils/testable/getBankAccountApproval'
import BankDetailsActions, { BankDetailsAction } from '../../store/BankDetails/BankDetails.actions'

interface BankDetailsPageState {
    currentView: ViewType
    disabledSubmit: boolean
    errors: Error[]
    inLineErrors: string[]
    setAccountHolderCity: (payload: string) => void
    setAccountHolderHouseNumber: (payload: string) => void
    setAccountHolderLastName: (payload: string) => void
    setAccountHolderName: (payload: string) => void
    setAccountHolderSalutation: (payload: string) => void
    setAccountHolderStreet: (payload: string) => void
    setAccountHolderTitle: (payload: string) => void
    setAccountHolderZip: (payload: string) => void
    setIban: (payload: string) => void
    setBic: (payload: string) => void
    setBankName: (payload: string) => void
    setAccountType: (payload: AccountType) => void
    setConsent: (payload: boolean) => void
    consent: boolean
    setDifferentAccountHolder: (payload: boolean) => void
    setAccountHolderAdditionalAddrInfo: (payload: string) => void
    setAccountHolderCountryAddr: (payload: string) => void
    setInvoiceSend: (payload: string) => void
    selectedInvoiceSendOption: string
    setBankDetailsPartial: (payload: Partial<BankDetailsState>) => void
    bankDetails: BankDetailsState
    setDayOfTransfer: (payload: string) => void
    dayOfTransfer: string
    customizeJsData: Customize | undefined
    showTwoConsentsIBAN: boolean | undefined
    consentLabel: string
    consentLabel2: string
    invoiceOptions: ConfigurationItemOption[]
    dayOfTransferOptions: ConfigurationItemOption[]
    clientData: { showBIC: boolean | undefined; showBankName: boolean | undefined }
}

export const useBankDetailsReducer: () => BankDetailsPageState = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions | BankDetailsActions>>()
    const { t } = useTranslation()
    const { B2B } = useURLParams()
    const {
        currentView,
        errors,
        inLineErrors,
        configurationMapState,
        dayOfTransfer,
        customizeJsData,
        bankDetails,
        clientData,
    } = useSelector((appState: AppState) => {
        return {
            currentView: appState.generalState.currentView,
            inLineErrors: appState.generalState.inLineErrors,
            errors: appState.generalState.errors,
            configurationMapState: appState.generalState.configuration,
            dayOfTransfer: appState.bankDetails.dayOfTransfer,
            customizeJsData: appState.generalState.customizeJsData,
            bankDetails: appState.bankDetails,
            clientData: {
                showBIC: appState.generalState.clientData?.showBIC,
                showBankName: appState.generalState.clientData?.showBankName,
            },
        }
    })

    const showTwoConsentsIBAN =
        customizeJsData &&
        customizeJsData.bankDetails.useSecondConsentIBAN === true &&
        bankDetails.accountType === 'IBAN'

    const setAccountHolderAdditionalAddrInfo = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_ADDITIONAL_ADDR_INFO, payload })
        },
        [dispatch],
    )

    const setAccountHolderCountryAddr = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_COUNTRY_ADDR, payload })
        },
        [dispatch],
    )

    const setAccountType = useCallback(
        (payload: AccountType) => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_TYPE, payload })
        },
        [dispatch],
    )

    const setDayOfTransfer = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_DAY_OF_TRANSFER, payload })
        },
        [dispatch],
    )

    const setBankDetailsPartial = useCallback(
        (payload: Partial<BankDetailsState>) => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_PARTIAL, payload })
        },
        [dispatch],
    )

    const setDifferentAccountHolder = useCallback(
        (payload: boolean) => {
            dispatch({ type: BankDetailsAction.SET_DIFFERENT_ACCOUNT_HOLDER, payload })
        },
        [dispatch],
    )

    const setAccountHolderSalutation = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_SALUTATION, payload })
        },
        [dispatch],
    )

    const setAccountHolderTitle = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_TITLE, payload })
        },
        [dispatch],
    )
    const setAccountHolderName = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_NAME, payload })
        },
        [dispatch],
    )
    const setAccountHolderLastName = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_LAST_NAME, payload })
        },
        [dispatch],
    )
    const setAccountHolderStreet = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_STREET, payload })
        },
        [dispatch],
    )
    const setAccountHolderHouseNumber = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_HOUSE_NUMBER, payload })
        },
        [dispatch],
    )
    const setAccountHolderZip = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_ACCOUNT_HOLDER_ZIP, payload })
        },
        [dispatch],
    )

    const setAccountHolderCity = useCallback(
        (payload: string) => {
            dispatch({
                type: BankDetailsAction.SET_ACCOUNT_HOLDER_CITY,
                payload,
            })
        },
        [dispatch],
    )
    const setIban = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_IBAN, payload })
        },
        [dispatch],
    )
    const setBic = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_BIC, payload })
        },
        [dispatch],
    )
    const setBankName = useCallback(
        (payload: string) => {
            dispatch({ type: BankDetailsAction.SET_BANK_NAME, payload })
        },
        [dispatch],
    )

    const setConsent = useCallback(
        (payload: boolean) => {
            dispatch({ type: BankDetailsAction.SET_CONSENT, payload })
        },
        [dispatch],
    )

    const addErrorToBankDetails = useCallback(
        (error: string) => {
            dispatch({
                type: GeneralStateAction.ADD_ERROR_TO_ERROR_CATEGORY,
                payload: {
                    category: ViewType.BANK_DETAILS,
                    error,
                },
            })
        },
        [dispatch],
    )

    const removeErrorFromBankDetails = useCallback(
        (error: string) =>
            dispatch({
                type: GeneralStateAction.REMOVE_ERROR_FROM_ERROR_CATEGORY,
                payload: { category: ViewType.BANK_DETAILS, error },
            }),
        [dispatch],
    )

    const setInLineErrors = useCallback(
        (payload: string[]) => {
            dispatch({ type: GeneralStateAction.SET_INLINE_ERRORS, payload })
        },
        [dispatch],
    )

    const setInvoiceSend = useCallback(
        (value: string) => {
            dispatch({ type: GeneralStateAction.SET_CONFIG_VALUE, payload: { identifier: 'invoiceSend', value } })
        },
        [dispatch],
    )

    const selectedInvoiceSendOption = configurationMapState.get('invoiceSend') as string

    const disabledSubmit = useMemo(() => {
        let missingFields = ['accountHolderZip']

        if (bankDetails.differentAccountHolder) {
            if (
                salutationIsCompany(bankDetails.accountHolderData.salutation) &&
                bankDetails.accountHolderData.company === ''
            ) {
                missingFields.push('accountHolderCompany')
            }
            if (
                customizeJsData &&
                customizeJsData.contactDataViewConfiguration &&
                customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm &&
                salutationIsCompany(bankDetails.accountHolderData.salutation) &&
                bankDetails.accountHolderData.companyLegalForm === ''
            ) {
                missingFields.push('accountHolderCompanyLegalForm')
            }
            if (bankDetails.accountHolderData.salutation === '') {
                missingFields.push('accountHolderSalutation')
            }
            if (bankDetails.accountHolderData.name === '') {
                missingFields.push('accountHolderName')
            }
            if (bankDetails.accountHolderData.lastName === '') {
                missingFields.push('accountHolderLastName')
            }
            if (bankDetails.accountHolderData.zip !== '') {
                if (bankDetails.accountHolderData.zip.length === 5) {
                    removeErrorFromBankDetails('zipMustBe5Digits')
                    const index = missingFields.indexOf('accountHolderZip')
                    if (index !== -1) {
                        missingFields.splice(index, 1)
                    }
                } else {
                    addErrorToBankDetails('zipMustBe5Digits')
                }
            } else {
                removeErrorFromBankDetails('zipMustBe5Digits')
            }
            if (bankDetails.accountHolderData.city === '') {
                missingFields.push('accountHolderCity')
            }
            if (bankDetails.accountHolderData.houseNumber === '') {
                missingFields.push('accountHolderHouseNumber')
            }
            if (bankDetails.accountHolderData.street === '') {
                missingFields.push('accountHolderStreet')
            }
        } else {
            removeErrorFromBankDetails('zipMustBe5Digits')
            missingFields = []
        }
        if (bankDetails.isValidating && bankDetails.accountType === AccountType.IBAN && bankDetails.iban === '') {
            setInLineErrors(['iban'])
        }
        if (clientData.showBIC && bankDetails.bic === '') {
            missingFields.push('bic')
        }
        if (bankDetails.accountType === 'TRANSFER') {
            removeErrorFromBankDetails('insertValidIBAN')
            const index = missingFields.indexOf('iban')
            if (index !== -1) {
                missingFields.splice(index, 1)
            }
            // reset the data if the userr added some data
            if (bankDetails.iban !== '') {
                setIban('')
            }
        }

        if (!bankDetails.consentChecked) {
            missingFields.push('consentChecked')
        }
        if (showTwoConsentsIBAN === true && bankDetails.accountType === AccountType.IBAN) {
            if (bankDetails.consentCollectMoneyFromAccountChecked === false)
                missingFields.push('consentCollectMoneyFromAccountChecked')
        }

        if (selectedInvoiceSendOption === undefined && customizeJsData?.bankDetails.invoiceSend.display) {
            missingFields.push('invoiceSend')
        }

        return missingFields.length > 0
    }, [bankDetails, selectedInvoiceSendOption])

    const labels = useMemo(() => {
        return getBankAccountApproval(bankDetails.accountType, bankDetails.differentAccountHolder)
    }, [bankDetails.accountType, bankDetails.differentAccountHolder])

    const invoiceOptions: ConfigurationItemOption[] = []
    if (customizeJsData?.bankDetails.invoiceSend.enableOptionEmail) {
        invoiceOptions.push({
            columnValue: 6,
            label: B2B ? 'viaEmail_B2B' : 'viaEmail',
            mobileColumnValue: 12,
            value: 'viaEmail',
        })
    }
    if (customizeJsData?.bankDetails.invoiceSend.enableOptionMail) {
        invoiceOptions.push({
            columnValue: 6,
            label: B2B ? 'viaMail_B2B' : 'viaMail',
            mobileColumnValue: 12,
            value: 'viaMail',
        })
    }

    const dayOfTransferOptions: ConfigurationItemOption[] = [
        {
            columnValue: 12,
            label: t('bankDetailsStrings.transfer15'),
            mobileColumnValue: 12,
            value: '15',
        },
        {
            columnValue: 12,
            label: t('bankDetailsStrings.transfer29'),
            mobileColumnValue: 12,
            value: '29',
        },
    ]

    const consent = bankDetails.consentChecked

    useEffect(() => {
        if (!customizeJsData?.bankDetails.displayIBAN) {
            setAccountType(AccountType.IBAN)
        }
    }, [])

    useEffect(() => {
        if (customizeJsData?.bankDetails?.preselected)
            if (configurationMapState.get('invoiceSend') === undefined)
                configurationMapState.set('invoiceSend', customizeJsData.bankDetails.preselected)
    }, [])

    return {
        currentView,
        setAccountType,
        disabledSubmit,
        errors,
        inLineErrors,
        setAccountHolderCity,
        setAccountHolderHouseNumber,
        setAccountHolderLastName,
        setAccountHolderName,
        setAccountHolderSalutation,
        setAccountHolderStreet,
        setAccountHolderTitle,
        setAccountHolderZip,
        setIban,
        setBic,
        setBankName,
        setConsent,
        consent,
        setDifferentAccountHolder,
        setAccountHolderAdditionalAddrInfo,
        setAccountHolderCountryAddr,
        setInvoiceSend,
        selectedInvoiceSendOption,
        setBankDetailsPartial,
        bankDetails,
        dayOfTransfer,
        customizeJsData,
        showTwoConsentsIBAN,
        consentLabel: labels.consentLabel,
        consentLabel2: labels.consentLabel2,
        invoiceOptions,
        dayOfTransferOptions,
        setDayOfTransfer,
        clientData,
    }
}
