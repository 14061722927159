import { PersistState } from 'redux-persist'
import { validatePhoneNumber } from '../../utils/testable/checkPhoneNumber'
import ContactDataActions, { ContactDataAction } from './ContactData.actions'

export interface ContactDataState {
    personalSalutation: string
    personalTitle: string
    personalName: string
    personalLastName: string
    personalEmail: string
    personalBirthDate: string
    personalTelephone: string
    personalMobilePhone: string
    useSalesPartnerEmail: boolean
    deviatingDeliveryAddress: boolean
    deviatingDeliveryDhl: boolean
    deliverySalutation: string
    deliveryTitle: string
    deliveryName: string
    deliveryLastName: string
    deliveryStreet: string
    deliveryHouseNumber: string
    deliveryZip: string
    deliveryCity: string
    deliveryCompany: string
    deliveryCompanyLegalForm: string
    deliveryAdditionalAddrInfo?: string
    deliveryCountryAddr?: string
    deviatingBillingAddress: boolean
    customerNumberVerified: boolean
    existingCustomerNumber: string
    existingCustomerRadio: string
    billingCountryAddr?: string
    billingSalutation: string
    billingTitle: string
    billingName: string
    billingLastName: string
    billingStreet: string
    billingHouseNumber: string
    billingZip: string
    billingCity: string
    billingCompany: string
    billingCompanyLegalForm: string
    company: string
    companyLegalForm: string
    companyRegisterEntry: string
    companyLocation: string
    companyId: string
    billingAdditionalAddrInfo?: string
    personalAdditionalAddressInfo?: string
    _persist: PersistState
}

export const initialContactDataAddressState: Partial<ContactDataState> = {
    deliverySalutation: '',
    deliveryTitle: '',
    deliveryName: '',
    deliveryLastName: '',
    deliveryStreet: '',
    deliveryHouseNumber: '',
    deliveryZip: '',
    deliveryCity: '',
    deliveryAdditionalAddrInfo: undefined,
}

export const initialContactDataState: ContactDataState = {
    personalSalutation: '',
    personalTitle: '',
    personalName: '',
    personalLastName: '',
    personalEmail: '',
    personalBirthDate: '',
    personalTelephone: '',
    personalMobilePhone: '',
    personalAdditionalAddressInfo: undefined,
    useSalesPartnerEmail: false,

    deviatingDeliveryDhl: false,

    deviatingDeliveryAddress: false,
    deliverySalutation: '',
    deliveryTitle: '',
    deliveryName: '',
    deliveryLastName: '',
    deliveryStreet: '',
    deliveryHouseNumber: '',
    deliveryZip: '',
    deliveryCity: '',
    deliveryAdditionalAddrInfo: undefined,
    deliveryCountryAddr: undefined,
    deliveryCompany: '',
    deliveryCompanyLegalForm: '',

    deviatingBillingAddress: false,
    billingSalutation: '',
    billingTitle: '',
    billingName: '',
    billingLastName: '',
    billingStreet: '',
    billingHouseNumber: '',
    billingZip: '',
    billingCity: '',
    billingAdditionalAddrInfo: undefined,
    billingCountryAddr: undefined,
    billingCompany: '',
    billingCompanyLegalForm: '',

    existingCustomerNumber: '',
    customerNumberVerified: false,
    existingCustomerRadio: '',

    company: '',
    companyLegalForm: '',
    companyRegisterEntry: '',
    companyLocation: '',
    companyId: '',

    // NOTE: uncomment this for testing
    //personalSalutation: 'Firma',
    //personalTitle: 'Dr.',
    //personalName: 'Rick',
    //personalLastName: 'Sanchez',
    //personalEmail: 'matthias.happ@qubidu.com',
    //personalBirthDate: '12.12.1999',
    //personalTelephone: '+49 40 95123 / 21',
    //personalMobilePhone: '+49 176/3312221',
    //personalAdditionalAddressInfo: 'check personal',

    //deviatingDeliveryAddress: true,
    //deliverySalutation: 'Firma',
    //deliveryTitle: 'deliveryTitle',
    //deliveryName: 'deliveryName',
    //deliveryLastName: 'deliveryLastName',
    //deliveryStreet: 'deliveryStreet',
    //deliveryHouseNumber: 'b',
    //deliveryZip: '12345',
    //deliveryCity: 'deliveryCity',
    //deliveryCompany: 'delivery company',
    //deliveryAdditionalAddrInfo: 'check delivery',
    //deviatingBillingAddress: true,

    //billingSalutation: 'Firma',
    //billingTitle: 'billingTitle',
    //billingName: 'billingName',
    //billingLastName: 'billingLastName',
    //billingStreet: 'billingStreet',
    //billingHouseNumber: 'a',
    //billingZip: '12345',
    //billingCity: 'billingCity',
    //billingAdditionalAddrInfo: 'check billing',
    //billingCompany: 'billing company',

    //company: 'personal Company',
    //companyLegalForm: 'KG',
    //companyRegisterEntry: '999',
    //companyLocation: 'Mars',
    //companyId: 'X/Æ/A-12',

    //useSalesPartnerEmail: false,

    _persist: { rehydrated: false, version: 1 } as PersistState,
}

function ContactDataReducer(
    state: ContactDataState = initialContactDataState,
    action: ContactDataActions,
): ContactDataState {
    switch (action.type) {
        case ContactDataAction.SET_TO_INIT_STATE:
            return initialContactDataState

        case ContactDataAction.SET_CONTACT_STATE_PARTIAL:
            return {
                ...state,
                ...action.payload,
            }
        case ContactDataAction.SET_PERSONAL_SALUTATION:
            return {
                ...state,
                personalSalutation: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_TITLE:
            return {
                ...state,
                personalTitle: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_NAME:
            return {
                ...state,
                personalName: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_LAST_NAME:
            return {
                ...state,
                personalLastName: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_EMAIL:
            return {
                ...state,
                personalEmail: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_BIRTH_DATE:
            return {
                ...state,
                personalBirthDate: action.payload,
            }
        case ContactDataAction.SET_PERSONAL_TELEPHONE:
            return {
                ...state,
                personalTelephone: validatePhoneNumber(action.payload),
            }
        case ContactDataAction.SET_PERSONAL_MOBILE_PHONE:
            return {
                ...state,
                personalMobilePhone: validatePhoneNumber(action.payload),
            }
        case ContactDataAction.TOGGLE_DEVIATING_DELIVERY_ADDRESS:
            return {
                ...state,
                deviatingDeliveryAddress: !state.deviatingDeliveryAddress,
            }
        case ContactDataAction.SET_DELIVERY_SALUTATION:
            return {
                ...state,
                deliverySalutation: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_TITLE:
            return {
                ...state,
                deliveryTitle: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_NAME:
            return {
                ...state,
                deliveryName: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_LAST_NAME:
            return {
                ...state,
                deliveryLastName: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_STREET:
            return {
                ...state,
                deliveryStreet: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_HOUSE_NUMBER:
            return {
                ...state,
                deliveryHouseNumber: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_ZIP:
            return {
                ...state,
                deliveryZip: action.payload,
            }
        case ContactDataAction.SET_DELIVERY_CITY:
            return {
                ...state,
                deliveryCity: action.payload,
            }
        case ContactDataAction.TOGGLE_DEVIATING_BILLING_ADDRESS:
            return {
                ...state,
                deviatingBillingAddress: !state.deviatingBillingAddress,
            }
        case ContactDataAction.SET_BILLING_SALUTATION:
            return {
                ...state,
                billingSalutation: action.payload,
            }
        case ContactDataAction.SET_BILLING_TITLE:
            return {
                ...state,
                billingTitle: action.payload,
            }
        case ContactDataAction.SET_BILLING_NAME:
            return {
                ...state,
                billingName: action.payload,
            }
        case ContactDataAction.SET_BILLING_LAST_NAME:
            return {
                ...state,
                billingLastName: action.payload,
            }
        case ContactDataAction.SET_BILLING_STREET:
            return {
                ...state,
                billingStreet: action.payload,
            }
        case ContactDataAction.SET_BILLING_HOUSE_NUMBER:
            return {
                ...state,
                billingHouseNumber: action.payload,
            }
        case ContactDataAction.SET_BILLING_ZIP:
            return {
                ...state,
                billingZip: action.payload,
            }
        case ContactDataAction.SET_BILLING_CITY:
            return {
                ...state,
                billingCity: action.payload,
            }
        case ContactDataAction.SET_EXISTING_CUSTOMER_NUMBER:
            return {
                ...state,
                existingCustomerNumber: action.payload,
            }
        case ContactDataAction.SET_EXISTING_CUSTOMER_RADIO:
            return {
                ...state,
                existingCustomerNumber: action.payload,
            }
        case ContactDataAction.SET_CONTACT_DATA_STATE_PARTIAL:
            return action.payload
        default:
            return state
    }
}

export default ContactDataReducer
