import { useLazyQuery } from '@apollo/client'
import { Box, CircularProgress } from '@material-ui/core'
import LoadOrderProcessData from 'graphql/queries/LoadOrderProcessData'
import { Customize, Query } from 'graphql/types'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AvailabilityCheckActions, { AvailabilityCheckAction } from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'

export interface CustomizeLoaderProps {
    children: JSX.Element
}

const CustomizeLoader = (props: CustomizeLoaderProps): JSX.Element => {
    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | BankDetailsActions
                | ContactDataActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()
    const { type, B2B } = useURLParams()
    const { orderProcessType, clientData } = useSelector((appState: AppState) => {
        return {
            orderProcessType: appState.generalState.orderProcessType,
            clientData: appState.generalState.clientData,
        }
    })
    const [isLoading, setIsLoading] = useState(true)

    //reset the stete in redux
    const resetState = useCallback(() => {
        dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: ContactDataAction.SET_TO_INIT_STATE })
        dispatch({ type: BankDetailsAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: GeneralStateAction.SET_TO_INIT_STATE, payload: { B2B } })
        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
    }, [dispatch])

    //set orderProcessType in redux store
    const setOrderProcessType = useCallback(
        (payload: string) => {
            dispatch({ type: GeneralStateAction.SET_ORDER_PROCESS_TYPE, payload })
        },
        [dispatch],
    )

    //set customize data in redux store
    const setCustomizeJsData = useCallback(
        (payload: { Customize: Customize; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SET_CUSTOMIZE_DATA, payload })
        },
        [dispatch],
    )

    //update customize data with client data
    const updateCustomizeJsData = useCallback(
        (payload: { useDesiredDate: boolean }) => {
            dispatch({ type: GeneralStateAction.UPDATE_CUSTOMIZE_DATA, payload })
        },
        [dispatch],
    )

    //set provider options in redux store
    const setProviderOptions = useCallback(
        (payload: string[]) => {
            dispatch({ type: PortabilityStateAction.SET_PROVIDER_OPTIONS, payload })
        },
        [dispatch],
    )

    //qraphql query for loading customize data
    const [loadOrderProcessData] = useLazyQuery<Query>(LoadOrderProcessData, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data): void => {
            setIsLoading(false)
            setCustomizeJsData({ Customize: data.loadOrderProcessData, B2B })
            document.title = data.loadOrderProcessData.globalConfiguration.title
        },
    })

    //load provider options
    useEffect(() => {
        // @ts-expect-error: init the previousProvider
        const previousProvider: string[] = window.previousProvider
        setProviderOptions([...previousProvider])

        document.title = 'Lade Bestellstrecke...'
    }, [])

    // set orderProcessType when the depending url param has changed
    useEffect(() => {
        if (type != orderProcessType && type !== undefined && orderProcessType !== '') {
            resetState()
        }
        setOrderProcessType(type ?? '')
    }, [type])

    // update customize when client data has changed
    useEffect(() => {
        if (clientData) {
            updateCustomizeJsData({ useDesiredDate: clientData.useDesiredDate })
        }
    }, [clientData])

    //load customize if orderProcessType has changed
    useEffect(() => {
        if (clientData == null) {
            setIsLoading(true)
            loadOrderProcessData({ variables: { type: orderProcessType } })
        } else {
            setIsLoading(false)
        }
    }, [orderProcessType])

    if (isLoading) {
        return (
            <Box width={'100vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress />
            </Box>
        )
    } else {
        return props.children
    }
}

export default CustomizeLoader
