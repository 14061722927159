export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    Any: any
    Time: Date
}

export enum AccountType {
    IBAN = 'IBAN',
    TRANSFER = 'TRANSFER',
}

export type Address = {
    __typename?: 'Address'
    addition?: Maybe<Scalars['String']>
    city: Scalars['String']
    houseNumber: Scalars['String']
    street: Scalars['String']
    zipcode: Scalars['String']
}

export type AddressData = {
    addition?: Maybe<Scalars['String']>
    city: Scalars['String']
    houseNumber: Scalars['String']
    street: Scalars['String']
    zipcode: Scalars['String']
}

export type AvailableProductsResponse = {
    __typename?: 'AvailableProductsResponse'
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    startOfContract?: Maybe<Scalars['Int']>
    supplyStart?: Maybe<Scalars['Int']>
    ClientData?: Maybe<Client>
    ProductCategories: Array<ProductCategory>
}

export type BankDetailsConfiguration = {
    __typename?: 'BankDetailsConfiguration'
    enablePage: Scalars['Boolean']
    consentLabel: ConsentLabel
    useSecondConsentIBAN?: Maybe<Scalars['Boolean']>
    useInvoiceFee?: Maybe<InvoiceFeeOption>
    useTransferFee?: Maybe<TransferFeeOption>
    displayIBAN: Scalars['Boolean']
    displayBIC: Scalars['Boolean']
    displayBankName: Scalars['Boolean']
    displayTransfer: Scalars['Boolean']
    displayDayOfTransfer: Scalars['Boolean']
    preselected?: Maybe<Scalars['String']>
    invoiceSend: InvoiceSend
    salutationOptionsAccount: Array<Scalars['String']>
}

export type BankDetailsData = {
    accountType: AccountType
    differentAccountHolder: Scalars['Boolean']
    consentChecked: Scalars['Boolean']
    accountHolderData: PersonalData
    bic: Scalars['String']
    bankName: Scalars['String']
    iban: Scalars['String']
    dayOfTransfer: Scalars['String']
    transfer: Scalars['Boolean']
}

export enum BillMode {
    ONE_TIME = 'ONE_TIME',
    RECURRING_MONTHLY = 'RECURRING_MONTHLY',
    IGNORE = 'IGNORE',
    VOUCHER = 'VOUCHER',
}

export type Booking = {
    __typename?: 'Booking'
    bookingNumber: Scalars['String']
    orderDate: Scalars['String']
    contractor: Scalars['String']
    address: Scalars['String']
}

export type BulkProdDbAddressInput = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    district?: Maybe<Scalars['String']>
    addition?: Maybe<Scalars['String']>
    wowi?: Maybe<Scalars['String']>
    mandant?: Maybe<Scalars['String']>
    marketingFrom?: Maybe<Scalars['Int']>
    provisionFrom?: Maybe<Scalars['Int']>
    startOfContract?: Maybe<Scalars['Int']>
    supplyStart?: Maybe<Scalars['Int']>
}

export type CatvAddress = {
    __typename?: 'CATVAddress'
    Salutation: Scalars['String']
    Title: Scalars['String']
    FirstName: Scalars['String']
    LastName: Scalars['String']
    Street: Scalars['String']
    HouseNumber: Scalars['String']
    ZipCode: Scalars['String']
    City: Scalars['String']
}

export type CatvBankData = {
    __typename?: 'CATVBankData'
    AccountHolder: Scalars['String']
    IsTransfer: Scalars['Boolean']
    Iban: Scalars['String']
}

export type CatvCustomerData = {
    __typename?: 'CATVCustomerData'
    CustomerID: Scalars['String']
    Salutation: Scalars['String']
    Title: Scalars['String']
    FirstName: Scalars['String']
    LastName: Scalars['String']
    Company: Scalars['String']
    CompanyLegalForm: Scalars['String']
    Email: Scalars['String']
    Birthdate: Scalars['String']
    Telephone: Scalars['String']
    Mobile: Scalars['String']
    ConnectionAddress: CatvAddress
    BillingAddress?: Maybe<CatvAddress>
    AccountHolderAddress?: Maybe<CatvAddress>
    BankData: CatvBankData
}

export type CatvCustomerDataResponse = {
    __typename?: 'CATVCustomerDataResponse'
    data?: Maybe<CatvCustomerData>
    productCategories?: Maybe<Array<Maybe<ProductCategory>>>
    status: Scalars['String']
    message: Scalars['String']
}

export type CategoryDependencies = {
    __typename?: 'CategoryDependencies'
    id: Scalars['String']
    config: ConfigurationProductCategoryDependencies
}

export type CheckVzfResponse = {
    __typename?: 'CheckVZFResponse'
    isValid: Scalars['Boolean']
    vzf?: Maybe<VzfData>
    vzfID?: Maybe<Scalars['String']>
}

export type CitiesResponse = {
    __typename?: 'CitiesResponse'
    status: Scalars['String']
    cities: Array<Scalars['String']>
}

export type Client = {
    __typename?: 'Client'
    id: Scalars['ID']
    salutation: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    company: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    logo: ClientFile
    pdfTemplate: ClientFile
    revocationFormPDFPath: Scalars['String']
    telephoneNumber: Scalars['String']
    email: Scalars['String']
    website: Scalars['String']
    management: Scalars['String']
    register: Scalars['String']
    registerCourt: Scalars['String']
    taxID: Scalars['String']
    linkAGB: Scalars['String']
    linkImpressum: Scalars['String']
    linkDataPrivacy: Scalars['String']
    salesPartner: Scalars['ID']
    salesPartnerFullName: Scalars['String']
    state: Scalars['String']
    clientID: Scalars['Int']
    useDesiredDate: Scalars['Boolean']
    showBIC: Scalars['Boolean']
    showBankName: Scalars['Boolean']
}

export type ClientFile = {
    __typename?: 'ClientFile'
    name: Scalars['String']
    path: Scalars['String']
    base64: Scalars['String']
    uploadDate: Scalars['Time']
    size: Scalars['Int']
}

export type ClientFileInput = {
    name: Scalars['String']
    base64: Scalars['String']
    size: Scalars['Int']
}

export type ClientInput = {
    id: Scalars['ID']
    salutation: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    company: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    logo: ClientFileInput
    pdfTemplate: ClientFileInput
    revocationFormPDFPath: Scalars['String']
    telephoneNumber: Scalars['String']
    email: Scalars['String']
    website: Scalars['String']
    management: Scalars['String']
    register: Scalars['String']
    registerCourt: Scalars['String']
    taxID: Scalars['String']
    linkAGB: Scalars['String']
    linkImpressum: Scalars['String']
    linkDataPrivacy: Scalars['String']
    salesPartner: Scalars['ID']
    state: Scalars['String']
    useDesiredDate: Scalars['Boolean']
    showBIC: Scalars['Boolean']
    showBankName: Scalars['Boolean']
}

export type CompleteOrderResponse = {
    __typename?: 'CompleteOrderResponse'
    success: Scalars['Boolean']
    orderNumber: Scalars['String']
}

export type ConditionConfiguration = {
    __typename?: 'ConditionConfiguration'
    identifier: Scalars['String']
    value: Array<Scalars['String']>
}

export type ConfigurationAppartmentData = {
    __typename?: 'ConfigurationAppartmentData'
    floorInformation: Scalars['String']
    floorInformationRequired: Scalars['Boolean']
    flatPosition: Scalars['String']
    flatPositionRequired: Scalars['Boolean']
    flatNumber: Scalars['String']
    flatNumberRequired: Scalars['Boolean']
}

export enum ConfigurationDisplayType {
    ONE_LINE = 'ONE_LINE',
    MULTI_LINE = 'MULTI_LINE',
    FREE = 'FREE',
}

export type ConfigurationHouseConnection = {
    __typename?: 'ConfigurationHouseConnection'
    identifier: Scalars['String']
    title: Scalars['String']
    preselected: Scalars['Int']
    options: Array<HouseConnectionOption>
}

export type ConfigurationInstallationService = {
    __typename?: 'ConfigurationInstallationService'
    title: Scalars['String']
    subtitle: Scalars['String']
    video: Scalars['String']
    option: ConfigurationInstallationServiceOption
}

export type ConfigurationInstallationServiceOption = {
    __typename?: 'ConfigurationInstallationServiceOption'
    title: Scalars['String']
    subtitle: Scalars['String']
    cost: Scalars['Int']
    information: Scalars['String']
}

export type ConfigurationItem = {
    key: Scalars['String']
    value: Scalars['String']
}

export type ConfigurationJunctionBox = {
    __typename?: 'ConfigurationJunctionBox'
    identifier: Scalars['String']
    title: Scalars['String']
    preselected: Scalars['Int']
    options: Array<JunctionBoxOption>
}

export type ConfigurationLink = {
    __typename?: 'ConfigurationLink'
    text: Scalars['String']
    url: Scalars['String']
}

export type ConfigurationOpticFiber = {
    __typename?: 'ConfigurationOpticFiber'
    identifier: Scalars['String']
    title: Scalars['String']
    preselected: Scalars['Int']
    options: Array<OpticFiberOption>
}

export type ConfigurationProductCategoryDependencies = {
    __typename?: 'ConfigurationProductCategoryDependencies'
    excluded: Array<Scalars['String']>
    required: Array<Scalars['String']>
}

export enum ConfigurationType {
    CHECKBOXES = 'CHECKBOXES',
    RADIO_BUTTONS = 'RADIO_BUTTONS',
    CONDITIONAL = 'CONDITIONAL',
}

export type ConsentLabel = {
    __typename?: 'ConsentLabel'
    selfAccountHolder: Scalars['String']
    differentAccountHolder: Scalars['String']
}

export type ContactDataViewConfiguration = {
    __typename?: 'ContactDataViewConfiguration'
    enablePage: Scalars['Boolean']
    activateSalesPartnerEmail: Scalars['Boolean']
    salesPartnerEmail: Scalars['String']
    enableCompanyTaxID: Scalars['Boolean']
    enableLegalForm: Scalars['Boolean']
    enableCompanyRegisterEntry: Scalars['Boolean']
    enableCompanyLocation: Scalars['Boolean']
    enableAlternativeLegalForm: Scalars['Boolean']
    existingCustomerForm: Scalars['Boolean']
    salutationOptionsPersonal: Array<Scalars['String']>
    salutationOptionsDeliver: Array<Scalars['String']>
    salutationOptionsBilling: Array<Scalars['String']>
    useAutoCompleteDeliveryAddress: Scalars['Boolean']
    useAutoCompleteBillingAddress: Scalars['Boolean']
    showCountryFieldDeliveryAddress: Scalars['Boolean']
    showCountryFieldBillingAddress: Scalars['Boolean']
    deliveryAddressConfiguration: DeliveryAddressConfiguration
}

export type ContractData = {
    customerNumber: Scalars['String']
    customerNumberVerified: Scalars['Boolean']
    hasCustomerNumber: Scalars['Boolean']
    desiredDate: Scalars['String']
    earliestDatePossible: Scalars['Boolean']
    installationDetails?: Maybe<InstallationDetailsState>
    configuration: Array<ConfigurationItem>
    selectedProductCategories: Array<SelectedProductCategory>
    orderProductDetails?: Maybe<OrderProductDetailsInput>
    startOfContract?: Maybe<Scalars['Int']>
}

export type CrossSellingConfiguration = {
    __typename?: 'CrossSellingConfiguration'
    enabledPage: Scalars['Boolean']
    rules: Array<CrossSellingRule>
}

export type CrossSellingRule = {
    __typename?: 'CrossSellingRule'
    target: Scalars['String']
    required: Array<Scalars['String']>
}

export type Customize = {
    __typename?: 'Customize'
    globalConfiguration: GlobalConfiguration
    voucherConfiguration: VoucherConfiguration
    portabilityConfiguration: PortabilityConfiguration
    productCategoriesConfiguration: ProductCategoryConfiguration
    telephoneOptions: TelephoneOptions
    installationConfiguration: InstallationConfiguration
    installationDetailsConfiguration: InstallationDetailsConfiguration
    bankDetails: BankDetailsConfiguration
    orderOverview: OrderOverviewConfiguration
    noProductAvailable: NoProductAvailable
    vzfConfiguration: VzfConfiguration
    contactDataViewConfiguration: ContactDataViewConfiguration
    orderConfirmationConfiguratiuon: OrderConfirmationConfiguratiuon
    optList: Array<Scalars['String']>
    crossSelling: CrossSellingConfiguration
}

export type CustomizeCheckboxEntry = {
    __typename?: 'CustomizeCheckboxEntry'
    value: Scalars['String']
    isDisplayedInB2B: Scalars['Boolean']
    displayProdDBInfoIcon?: Maybe<Scalars['Boolean']>
}

export type CustomizeEntry = {
    __typename?: 'CustomizeEntry'
    name: Scalars['String']
    value: Scalars['Float']
}

export type DeliveryAddressConfiguration = {
    __typename?: 'DeliveryAddressConfiguration'
    enabled: Scalars['Boolean']
    dhlEnabled: Scalars['Boolean']
    enabledDetectOwnHardware: Scalars['Boolean']
    ownHardwareID: Array<Scalars['String']>
}

export type Discount = {
    __typename?: 'Discount'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description: Scalars['String']
    descriptionBasket: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    monthlyDiscounts: Array<MonthPrice>
    discountLength: Scalars['Int']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    content: Scalars['String']
    messages?: Maybe<Array<Scalars['String']>>
    title: Scalars['String']
}

export type ExtraPersonalData = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    district: Scalars['String']
    addition: Scalars['String']
    additionalInfo: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthDate: Scalars['String']
    telephone: Scalars['String']
    mobilePhone: Scalars['String']
    useSalesPartnerEmail: Scalars['Boolean']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    companyRegisterEntry: Scalars['String']
    companyLocation: Scalars['String']
    companyId: Scalars['String']
}

export type Fee = {
    __typename?: 'Fee'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description: Scalars['String']
    descriptionBasket: Scalars['String']
    billMode: BillMode
    included: Scalars['Boolean']
    optional: Scalars['Boolean']
    processUnique: Scalars['Boolean']
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    feeLength: Scalars['Int']
    monthlyFees: Array<MonthPrice>
    discounts: Array<Discount>
}

export type FooterContent = {
    name: Scalars['String']
    page: Scalars['Int']
}

export type FooterLink = {
    __typename?: 'FooterLink'
    i18Key: Scalars['String']
    link: Scalars['String']
}

export type GlobalConfiguration = {
    __typename?: 'GlobalConfiguration'
    title: Scalars['String']
    showBasketInfoIcons: Scalars['Boolean']
    useCustomCombiDefaultSelection: Scalars['Boolean']
    enablePresentation: Scalars['Boolean']
    enableHardwareSelection: Array<Scalars['String']>
    logoClickWebsite?: Maybe<Scalars['String']>
    footer: Array<FooterLink>
    icons: Icons
    telephoneNumber: Scalars['String']
    vzfCheckOwnButton?: Maybe<Scalars['Boolean']>
    enableAddressAddition?: Maybe<Scalars['Boolean']>
    enableAddressDistrict?: Maybe<Scalars['Boolean']>
    enableLoadingClient?: Maybe<Scalars['Boolean']>
    productGroups?: Maybe<Array<Scalars['String']>>
    infoBox?: Maybe<Array<Maybe<InfoBoxItem>>>
    disableStartLogo?: Maybe<Scalars['Boolean']>
}

export type HouseConnectionOption = {
    __typename?: 'HouseConnectionOption'
    title: Scalars['String']
    badgeIdentifier?: Maybe<Scalars['String']>
    information: Scalars['String']
}

export type Icons = {
    __typename?: 'Icons'
    header: Scalars['String']
    footer: Scalars['String']
    thankYou: Scalars['String']
}

export type InfoBoxEntry = {
    __typename?: 'InfoBoxEntry'
    message: Scalars['String']
    condition?: Maybe<Scalars['String']>
}

export type InfoBoxItem = {
    __typename?: 'InfoBoxItem'
    path: Scalars['String']
    messages: Array<InfoBoxEntry>
}

export type InstallationConfiguration = {
    __typename?: 'InstallationConfiguration'
    enablePage: Scalars['Boolean']
    minInstallationDate: Scalars['Int']
    maxInstallationDate: Scalars['Int']
}

export type InstallationDetailsConfiguration = {
    __typename?: 'InstallationDetailsConfiguration'
    enablePage: Scalars['Boolean']
    i18Key: Scalars['String']
    displayInContactPage?: Maybe<Scalars['Boolean']>
    appartmentData?: Maybe<ConfigurationAppartmentData>
    junctionBox: ConfigurationJunctionBox
    houseConnection?: Maybe<ConfigurationHouseConnection>
    opticFiber?: Maybe<ConfigurationOpticFiber>
    installationService: ConfigurationInstallationService
}

export type InstallationDetailsState = {
    floorInformation: Scalars['String']
    flatPosition: Scalars['String']
    flatNumber: Scalars['String']
    junctionBox: Scalars['String']
    houseConnection: Scalars['String']
    houseConnectionInput: Scalars['String']
    opticFiber: Scalars['String']
    opticFiberInput: Scalars['String']
    installationService: Scalars['Float']
}

export type InvoiceFeeOption = {
    __typename?: 'InvoiceFeeOption'
    feeId: Scalars['String']
    visibleB2B: Scalars['Boolean']
}

export type InvoiceSend = {
    __typename?: 'InvoiceSend'
    display: Scalars['Boolean']
    enableOptionMail: Scalars['Boolean']
    enableOptionEmail: Scalars['Boolean']
}

export type JunctionBoxOption = {
    __typename?: 'JunctionBoxOption'
    title: Scalars['String']
    badgeIdentifier?: Maybe<Scalars['String']>
    information: Scalars['String']
}

export type LoadOrderProgressResponse = {
    __typename?: 'LoadOrderProgressResponse'
    data: Scalars['String']
    vzfID: Scalars['String']
    vzfData: VzfData
}

export type Loadstate = {
    __typename?: 'Loadstate'
    errors: Array<Scalars['String']>
    loading: Scalars['Boolean']
}

export type LoggedUser = {
    __typename?: 'LoggedUser'
    email: Scalars['String']
    customerNumber: Scalars['String']
    name: Scalars['String']
    roles: Array<Scalars['String']>
    clientId?: Maybe<Scalars['Int']>
    clientName: Scalars['String']
}

export type Marketing = {
    __typename?: 'Marketing'
    id: Scalars['ID']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    addition?: Maybe<Scalars['String']>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
}

export type MarketingEditInput = {
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    ids: Array<Scalars['ID']>
}

export type MarketingInput = {
    id: Scalars['ID']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    addition?: Maybe<Scalars['String']>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
}

export type MonthPrice = {
    __typename?: 'MonthPrice'
    initMonth: Scalars['Int']
    endMonth: Scalars['Int']
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
}

export type MultipleSelectOptionItem = {
    key: Scalars['String']
    value: Scalars['Int']
}

export type MultipleSelectOptions = {
    __typename?: 'MultipleSelectOptions'
    key: Scalars['String']
    value: Scalars['Int']
}

export type Mutation = {
    __typename?: 'Mutation'
    completeOrderProgress: CompleteOrderResponse
    saveOrderProgress?: Maybe<Response>
    sendVZFMail: Response
    saveVZF: Response
    sendTouchPoint: Response
    editUser?: Maybe<Response>
    createUser?: Maybe<Response>
    deleteUser?: Maybe<Response>
    editVoucher?: Maybe<Response>
    createVoucher?: Maybe<Response>
    deleteVoucher?: Maybe<Response>
    bulkDeleteMarketing?: Maybe<Response>
    createMarketing?: Maybe<Response>
    editMarketing?: Maybe<Response>
    editProvisionings: Response
    createProvisionings: Response
    createClient?: Maybe<Response>
    editClient?: Maybe<Response>
    deleteClient?: Maybe<Response>
    changeStateOfClient?: Maybe<Response>
    createProdDBAddress?: Maybe<Response>
    createBulkProdDBAddress?: Maybe<Response>
    editProdDBAddress?: Maybe<Response>
    deleteProdDBAddress?: Maybe<Response>
    multiEditProdDBAddress?: Maybe<Response>
    createProdDBProduct?: Maybe<Response>
    editProdDBProduct?: Maybe<Response>
    deleteProdDBProduct?: Maybe<Response>
    multiEditProdDBProduct?: Maybe<Response>
    editProdDBOrderStatus?: Maybe<Response>
    createProdDBCategory?: Maybe<Response>
    editProdDBCategory?: Maybe<Response>
    deleteProdDBCategory?: Maybe<Response>
    createProdDBOption?: Maybe<Response>
    editProdDBOption?: Maybe<Response>
    deleteProdDBOption?: Maybe<Response>
    multiEditProdDBOption?: Maybe<Response>
    createProdDBOptionGroup?: Maybe<Response>
    editProdDBOptionGroup?: Maybe<Response>
    deleteProdDBOptionGroup?: Maybe<Response>
}

export type MutationCompleteOrderProgressArgs = {
    order: OrderDataInput
    pdf: PdfDataInput
    vzfSummaryData: VzfpdfData
}

export type MutationSaveOrderProgressArgs = {
    email: Scalars['String']
    data: Scalars['String']
    key: Scalars['String']
    salutation: Scalars['String']
    vzfID: Scalars['String']
    clientID?: Maybe<Scalars['Int']>
    orderProcessType: Scalars['String']
}

export type MutationSendVzfMailArgs = {
    vzfID: Scalars['String']
    email: Scalars['String']
    clientID?: Maybe<Scalars['Int']>
}

export type MutationSaveVzfArgs = {
    input: VzfDataInput
    vzfID: Scalars['String']
    pdfData: VzfpdfData
    b2b: Scalars['Boolean']
    clientID?: Maybe<Scalars['Int']>
}

export type MutationSendTouchPointArgs = {
    input: TouchPointInput
}

export type MutationEditUserArgs = {
    input: UserInput
}

export type MutationCreateUserArgs = {
    input: UserInput
}

export type MutationDeleteUserArgs = {
    id: Scalars['String']
}

export type MutationEditVoucherArgs = {
    input: VoucherInput
}

export type MutationCreateVoucherArgs = {
    input: VoucherInput
}

export type MutationDeleteVoucherArgs = {
    id: Scalars['String']
}

export type MutationBulkDeleteMarketingArgs = {
    ids: Array<Scalars['String']>
}

export type MutationCreateMarketingArgs = {
    input: MarketingInput
}

export type MutationEditMarketingArgs = {
    input: MarketingEditInput
}

export type MutationEditProvisioningsArgs = {
    provisionings: Array<Scalars['ID']>
    state: Scalars['String']
}

export type MutationCreateProvisioningsArgs = {
    from: Scalars['Time']
    to: Scalars['Time']
}

export type MutationCreateClientArgs = {
    input: ClientInput
}

export type MutationEditClientArgs = {
    input: ClientInput
}

export type MutationDeleteClientArgs = {
    ids: Array<Scalars['ID']>
}

export type MutationChangeStateOfClientArgs = {
    ids: Array<Scalars['ID']>
    state: Scalars['String']
}

export type MutationCreateProdDbAddressArgs = {
    input: ProdDbAddressInput
}

export type MutationCreateBulkProdDbAddressArgs = {
    input: Array<BulkProdDbAddressInput>
}

export type MutationEditProdDbAddressArgs = {
    id: Scalars['ID']
    input: ProdDbAddressPartialInput
}

export type MutationDeleteProdDbAddressArgs = {
    ids: Array<Scalars['ID']>
}

export type MutationMultiEditProdDbAddressArgs = {
    ids: Array<Scalars['ID']>
    input: ProdDbAddressPartialInput
}

export type MutationCreateProdDbProductArgs = {
    input: ProdDbProductInput
}

export type MutationEditProdDbProductArgs = {
    id: Scalars['ID']
    input: ProdDbProductPartialInput
}

export type MutationDeleteProdDbProductArgs = {
    ids: Array<Scalars['ID']>
}

export type MutationMultiEditProdDbProductArgs = {
    ids: Array<Scalars['ID']>
    input: ProdDbProductPartialInput
}

export type MutationEditProdDbOrderStatusArgs = {
    ids: Array<Scalars['ID']>
    status: Scalars['String']
}

export type MutationCreateProdDbCategoryArgs = {
    input: ProdDbCategoryInput
}

export type MutationEditProdDbCategoryArgs = {
    id: Scalars['ID']
    input: ProdDbCategoryPartialInput
}

export type MutationDeleteProdDbCategoryArgs = {
    ids: Array<Scalars['ID']>
}

export type MutationCreateProdDbOptionArgs = {
    input: ProdDbOptionInput
}

export type MutationEditProdDbOptionArgs = {
    id: Scalars['ID']
    input: ProdDbOptionPartialInput
}

export type MutationDeleteProdDbOptionArgs = {
    ids: Array<Scalars['ID']>
}

export type MutationMultiEditProdDbOptionArgs = {
    ids: Array<Scalars['ID']>
    input: ProdDbOptionPartialInput
}

export type MutationCreateProdDbOptionGroupArgs = {
    input: ProdDbOptionGroupInput
}

export type MutationEditProdDbOptionGroupArgs = {
    id: Scalars['ID']
    input: ProdDbOptionGroupPartialInput
}

export type MutationDeleteProdDbOptionGroupArgs = {
    ids: Array<Scalars['ID']>
}

export type NoProductAvailable = {
    __typename?: 'NoProductAvailable'
    haveLink: Scalars['Boolean']
    link: Scalars['String']
}

export type OptEntry = {
    key: Scalars['String']
    value: Scalars['String']
}

export type OpticFiberOption = {
    __typename?: 'OpticFiberOption'
    title: Scalars['String']
    badgeIdentifier?: Maybe<Scalars['String']>
    information: Scalars['String']
}

export type Option = {
    __typename?: 'Option'
    id: Scalars['ID']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dataSheet?: Maybe<Scalars['String']>
    descriptionBasket: Scalars['String']
    badgeIdentifier?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    preconditionArticle: Array<Scalars['String']>
    excludedArticles: Array<Scalars['String']>
    requiredArticles: Array<Scalars['String']>
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    contractLength: Scalars['String']
    discounts: Array<Discount>
    fees: Array<Fee>
    max?: Maybe<Scalars['Int']>
    min?: Maybe<Scalars['Int']>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
    minimumContractPeriod?: Maybe<Scalars['String']>
    cancellationPeriod?: Maybe<Scalars['String']>
    contractExtension?: Maybe<Scalars['String']>
    isHardware: Scalars['Boolean']
}

export type OptionCategory = {
    __typename?: 'OptionCategory'
    id: Scalars['ID']
    title: Scalars['String']
    type: Scalars['String']
    identifier: Scalars['String']
    titleBasket: Scalars['String']
    information?: Maybe<Scalars['String']>
    max: Scalars['Int']
    min: Scalars['Int']
    options: Array<Option>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
}

export type OptionConfiguration = {
    __typename?: 'OptionConfiguration'
    labelKey: Scalars['String']
    value: Scalars['String']
    preselected: Scalars['Boolean']
}

export type OrderCategory = {
    __typename?: 'OrderCategory'
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    description: Scalars['String']
    products: OrderProduct
}

export type OrderCategoryInput = {
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    description: Scalars['String']
    products: OrderProductInput
}

export type OrderConfirmationConfiguratiuon = {
    __typename?: 'OrderConfirmationConfiguratiuon'
    enableTouchPoints: Scalars['Boolean']
    touchPoints: Array<TouchPointEntry>
}

export type OrderDataInput = {
    personalAddress: ExtraPersonalData
    deviatingDeliveryAddress: Scalars['Boolean']
    deviatingDeliveryDhl: Scalars['Boolean']
    deviatingBillingAddress: Scalars['Boolean']
    deliveryAddress: PersonalData
    billingAddress: PersonalData
    bankDetails: BankDetailsData
    portability: PortabilityData
    contractData: ContractData
    opt: Array<OptEntry>
    multipleSelectOptionList: Array<MultipleSelectOptionItem>
    distributor: Scalars['String']
    vouchers: Array<VoucherDataInput>
    b2b: Scalars['Boolean']
    vzfID: Scalars['String']
    clientID?: Maybe<Scalars['Int']>
    clientCompany?: Maybe<Scalars['String']>
    customizeType: Scalars['String']
    salesPartner?: Maybe<Scalars['String']>
}

export type OrderOverviewCheckboxCategory = {
    __typename?: 'OrderOverviewCheckboxCategory'
    header: Scalars['String']
    subheader?: Maybe<Scalars['String']>
    helperText?: Maybe<Scalars['String']>
    checkboxes: Array<Scalars['String']>
}

export type OrderOverviewCheckboxCategoryForLegalTerms = {
    __typename?: 'OrderOverviewCheckboxCategoryForLegalTerms'
    header: Scalars['String']
    subheader?: Maybe<Scalars['String']>
    helperText?: Maybe<Scalars['String']>
    checkboxes: Array<CustomizeCheckboxEntry>
}

export type OrderOverviewConfiguration = {
    __typename?: 'OrderOverviewConfiguration'
    vzf: OrderOverviewCheckboxCategory
    legalTerms: OrderOverviewCheckboxCategoryForLegalTerms
    newsletters?: Maybe<OrderOverviewCheckboxCategory>
    sendOverView?: Maybe<OrderOverviewCheckboxCategory>
    showPeriodOfNoticeInfo: Scalars['Boolean']
    showRightOfWithdrawalInfo: Scalars['Boolean']
    showTransferMonthlyTitle?: Maybe<Scalars['Boolean']>
    isEditable: Scalars['Boolean']
}

export type OrderProduct = {
    __typename?: 'OrderProduct'
    id: Scalars['String']
    internalID: Scalars['String']
    internalName: Scalars['String']
    title: Scalars['String']
    description: Scalars['String']
    productInfoSheet: Scalars['String']
    subCategory: Scalars['String']
    groupBy: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    information: Scalars['String']
    minimumContractPeriod: Scalars['String']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
    fees: Array<ProductFree>
    discounts: Array<ProductDiscount>
    productTypes: Array<ProductTypes>
}

export type OrderProductDetails = {
    __typename?: 'OrderProductDetails'
    monthlyPrice: Scalars['Float']
    oneTimePrice: Scalars['Float']
    categories: Array<OrderCategory>
}

export type OrderProductDetailsInput = {
    monthlyPrice: Scalars['Float']
    oneTimePrice: Scalars['Float']
    categories: Array<OrderCategoryInput>
}

export type OrderProductInput = {
    id: Scalars['String']
    internalID: Scalars['String']
    internalName: Scalars['String']
    title: Scalars['String']
    description: Scalars['String']
    productInfoSheet: Scalars['String']
    subCategory: Scalars['String']
    groupBy: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    information: Scalars['String']
    minimumContractPeriod: Scalars['String']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
    fees: Array<ProductFreeInput>
    discounts: Array<ProductDiscountInput>
    productTypes: Array<ProductTypesInput>
}

export type OrderedProduct = {
    __typename?: 'OrderedProduct'
    name: Scalars['String']
    price: Scalars['Float']
}

export type PdfContent = {
    Key: Scalars['String']
    Value: Scalars['String']
    Description: Array<Scalars['String']>
}

export type PdfDataInput = {
    PDFSummaryEntries: Array<PdfEntry>
    costs: VzfpdfCost
}

export type PdfEntry = {
    Headline: Scalars['String']
    Content: Array<PdfContent>
}

export type PersonalData = {
    zipcode: Scalars['String']
    city: Scalars['String']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    country: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
}

export type PortabilityConfiguration = {
    __typename?: 'PortabilityConfiguration'
    contractHolderMaximumNumber: Scalars['Int']
    portabilityMaximumNumber: Scalars['Int']
    previousProvider: Scalars['Boolean']
    previousContractHolder: Scalars['Boolean']
    previousContractAddress: Scalars['Boolean']
    previousContractTermination: Scalars['Boolean']
    showPortability: ShowPortability
    B2BpreviousContractTerminationLink: Scalars['String']
    B2CpreviousContractTerminationLink: Scalars['String']
}

export type PortabilityContractHolder = {
    firstName: Scalars['String']
    lastName: Scalars['String']
}

export type PortabilityData = {
    address: AddressData
    contractHolderOptions: Array<PortabilityContractHolder>
    endOfContract: Scalars['String']
    phoneOptions: Array<PortabilityTelephone>
    selectedProvider: Scalars['String']
    selectedRadios: Array<Scalars['String']>
    landLineContractOptionsExit: Scalars['Boolean']
}

export type PortabilityTelephone = {
    areaCode: Scalars['String']
    number: Scalars['String']
}

export type PostCodeAddress = {
    __typename?: 'PostCodeAddress'
    country: Scalars['String']
    locality: Scalars['String']
    street: Scalars['String']
    postcode: Scalars['String']
    building: Scalars['String']
    buildingNumber: Scalars['Int']
    buildingNumberAddition: Scalars['String']
}

export type PostCodeCountry = {
    __typename?: 'PostCodeCountry'
    name: Scalars['String']
    iso3Code: Scalars['String']
}

export type PostCodeDetail = {
    __typename?: 'PostCodeDetail'
    language: Scalars['String']
    location: PostCodeDetailLocation
    country: PostCodeCountry
    mailLines: Array<Scalars['String']>
    address: PostCodeAddress
}

export type PostCodeDetailLocation = {
    __typename?: 'PostCodeDetailLocation'
    latitude: Scalars['Float']
    longitude: Scalars['Float']
    precision: Scalars['String']
}

export type PostCodeInput = {
    context: Scalars['String']
    term: Scalars['String']
}

export type PostCodeObject = {
    __typename?: 'PostCodeObject'
    value: Scalars['String']
    label: Scalars['String']
    description: Scalars['String']
    precision: Scalars['String']
    context: Scalars['String']
    highlights: Array<Array<Scalars['Int']>>
}

export type PostCodeResponse = {
    __typename?: 'PostCodeResponse'
    matches: Array<PostCodeObject>
}

export type ProdDbAddress = {
    __typename?: 'ProdDBAddress'
    id: Scalars['String']
    internalID: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    district: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    addition: Scalars['String']
    wowi: Scalars['String']
    state: Scalars['String']
    mandant: Scalars['String']
    products: Array<ProdDbAddressProductEntry>
    marketingFrom: Scalars['Int']
    provisionFrom: Scalars['Int']
    startOfContract: Scalars['Int']
    supplyStart: Scalars['Int']
}

export type ProdDbAddressData = {
    __typename?: 'ProdDBAddressData'
    addition?: Maybe<Scalars['String']>
    city: Scalars['String']
    houseNumber: Scalars['String']
    street: Scalars['String']
    zipcode: Scalars['String']
}

export type ProdDbAddressInput = {
    id: Scalars['String']
    internalID: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    district: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    addition: Scalars['String']
    wowi: Scalars['String']
    state: Scalars['String']
    mandant: Scalars['String']
    products: Array<Scalars['String']>
    marketingFrom: Scalars['Int']
    provisionFrom: Scalars['Int']
    startOfContract: Scalars['Int']
    supplyStart: Scalars['Int']
}

export type ProdDbAddressPartialInput = {
    internalID?: Maybe<Scalars['String']>
    zipcode?: Maybe<Scalars['String']>
    city?: Maybe<Scalars['String']>
    district?: Maybe<Scalars['String']>
    street?: Maybe<Scalars['String']>
    housenumber?: Maybe<Scalars['String']>
    addition?: Maybe<Scalars['String']>
    wowi?: Maybe<Scalars['String']>
    state?: Maybe<Scalars['String']>
    mandant?: Maybe<Scalars['String']>
    products?: Maybe<Array<Scalars['String']>>
    marketingFrom?: Maybe<Scalars['Int']>
    provisionFrom?: Maybe<Scalars['Int']>
    startOfContract?: Maybe<Scalars['Int']>
    supplyStart?: Maybe<Scalars['Int']>
}

export type ProdDbAddressProductEntry = {
    __typename?: 'ProdDBAddressProductEntry'
    name: Scalars['String']
    internalName: Scalars['String']
    id: Scalars['String']
}

export type ProdDbBankDetailsData = {
    __typename?: 'ProdDBBankDetailsData'
    accountType: AccountType
    differentAccountHolder: Scalars['Boolean']
    consentChecked: Scalars['Boolean']
    accountHolderData: ProdDbPersonalData
    iban: Scalars['String']
    bic: Scalars['String']
    bankName: Scalars['String']
    dayOfTransfer: Scalars['String']
    transfer: Scalars['Boolean']
}

export type ProdDbCategory = {
    __typename?: 'ProdDBCategory'
    id: Scalars['String']
    products: Array<ProdDbAddressProductEntry>
    title: Scalars['String']
    identifier: Scalars['String']
    description: Scalars['String']
    sortIndex: Scalars['Int']
}

export type ProdDbCategoryInput = {
    id: Scalars['String']
    products: Array<Scalars['String']>
    title: Scalars['String']
    identifier: Scalars['String']
    description: Scalars['String']
    sortIndex: Scalars['Int']
}

export type ProdDbCategoryPartialInput = {
    products?: Maybe<Array<Scalars['String']>>
    title?: Maybe<Scalars['String']>
    identifier?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    sortIndex?: Maybe<Scalars['Int']>
}

export type ProdDbConfigurationItem = {
    __typename?: 'ProdDBConfigurationItem'
    key: Scalars['String']
    value: Scalars['String']
}

export type ProdDbContractData = {
    __typename?: 'ProdDBContractData'
    customerNumber: Scalars['String']
    hasCustomerNumber: Scalars['Boolean']
    customerNumberVerified: Scalars['Boolean']
    desiredDate: Scalars['String']
    earliestDatePossible: Scalars['Boolean']
    startOfContract?: Maybe<Scalars['Int']>
    installationDetails?: Maybe<ProdDbInstallationDetailsState>
    configuration: Array<ProdDbConfigurationItem>
    selectedProductCategories: Array<ProdDbSelectedProductCategory>
    orderProductDetails?: Maybe<OrderProductDetails>
}

export type ProdDbDiscount = {
    __typename?: 'ProdDBDiscount'
    price: Scalars['Float']
    month: Scalars['Int']
}

export type ProdDbDiscountInput = {
    price: Scalars['Float']
    month: Scalars['Int']
}

export type ProdDbExtraPersonalData = {
    __typename?: 'ProdDBExtraPersonalData'
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    district: Scalars['String']
    addition: Scalars['String']
    additionalInfo: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthDate: Scalars['String']
    telephone: Scalars['String']
    mobilePhone: Scalars['String']
    useSalesPartnerEmail: Scalars['Boolean']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    companyRegisterEntry: Scalars['String']
    companyLocation: Scalars['String']
    companyId: Scalars['String']
}

export type ProdDbFee = {
    __typename?: 'ProdDBFee'
    title: Scalars['String']
    info: Scalars['String']
    billMode: BillMode
    price: Scalars['Float']
    Discounts: Array<ProdDbDiscount>
}

export type ProdDbFeeInput = {
    title: Scalars['String']
    info: Scalars['String']
    billMode: BillMode
    price: Scalars['Float']
    Discounts: Array<ProdDbDiscountInput>
}

export type ProdDbInstallationDetailsState = {
    __typename?: 'ProdDBInstallationDetailsState'
    floorInformation: Scalars['String']
    flatPosition: Scalars['String']
    flatNumber: Scalars['String']
    junctionBox: Scalars['String']
    houseConnection: Scalars['String']
    houseConnectionInput: Scalars['String']
    opticFiber: Scalars['String']
    opticFiberInput: Scalars['String']
    installationService: Scalars['Float']
}

export type ProdDbMultipleSelectOptionItem = {
    __typename?: 'ProdDBMultipleSelectOptionItem'
    key: Scalars['String']
    value: Scalars['Int']
}

export type ProdDbOptEntry = {
    __typename?: 'ProdDBOptEntry'
    key: Scalars['String']
    value: Scalars['String']
}

export type ProdDbOption = {
    __typename?: 'ProdDBOption'
    id: Scalars['String']
    internalID: Scalars['String']
    title: Scalars['String']
    subtitle: Scalars['String']
    description: Scalars['String']
    information: Scalars['String']
    BillMode: Scalars['String']
    PriceGross: Scalars['Float']
    PriceNet: Scalars['Float']
    PriceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    sortIndex: Scalars['Int']
    Fees: Array<ProdDbFee>
    Discounts: Array<ProdDbDiscount>
}

export type ProdDbOptionGroup = {
    __typename?: 'ProdDBOptionGroup'
    id: Scalars['String']
    title: Scalars['String']
    internalID: Scalars['String']
    type: Scalars['String']
    titleBasket: Scalars['String']
    information: Scalars['String']
    max: Scalars['Int']
    min: Scalars['Int']
    sortIndex: Scalars['Int']
    options: Array<ProdDbOptionGroupOptionsEntry>
}

export type ProdDbOptionGroupInput = {
    id: Scalars['String']
    title: Scalars['String']
    internalID: Scalars['String']
    type: Scalars['String']
    titleBasket: Scalars['String']
    information: Scalars['String']
    max: Scalars['Int']
    min: Scalars['Int']
    sortIndex: Scalars['Int']
    options: Array<Scalars['String']>
}

export type ProdDbOptionGroupOptionsEntry = {
    __typename?: 'ProdDBOptionGroupOptionsEntry'
    title: Scalars['String']
    id: Scalars['String']
}

export type ProdDbOptionGroupPartialInput = {
    internalID?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    titleBasket?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    max?: Maybe<Scalars['Int']>
    min?: Maybe<Scalars['Int']>
    sortIndex?: Maybe<Scalars['Int']>
    options?: Maybe<Array<Scalars['String']>>
}

export type ProdDbOptionInput = {
    id: Scalars['String']
    internalID: Scalars['String']
    title: Scalars['String']
    subtitle: Scalars['String']
    description: Scalars['String']
    information: Scalars['String']
    BillMode: Scalars['String']
    PriceGross: Scalars['Float']
    PriceNet: Scalars['Float']
    PriceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    sortIndex: Scalars['Int']
    Fees: Array<ProdDbFeeInput>
    Discounts: Array<ProdDbDiscountInput>
}

export type ProdDbOptionPartialInput = {
    internalID?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    BillMode?: Maybe<Scalars['String']>
    PriceGross?: Maybe<Scalars['Float']>
    PriceNet?: Maybe<Scalars['Float']>
    PriceVat?: Maybe<Scalars['Float']>
    included?: Maybe<Scalars['Boolean']>
    isDefault?: Maybe<Scalars['Boolean']>
    sortIndex?: Maybe<Scalars['Int']>
    Fees?: Maybe<Array<Maybe<ProdDbFeeInput>>>
    Discounts?: Maybe<Array<Maybe<ProdDbDiscountInput>>>
}

export type ProdDbOrder = {
    __typename?: 'ProdDBOrder'
    id: Scalars['String']
    personalAddress: ProdDbExtraPersonalData
    deviatingDeliveryAddress: Scalars['Boolean']
    deviatingDeliveryDhl: Scalars['Boolean']
    deviatingBillingAddress: Scalars['Boolean']
    deliveryAddress: ProdDbPersonalData
    billingAddress: ProdDbPersonalData
    bankDetails: ProdDbBankDetailsData
    portability: ProdDbPortabilityData
    contractData: ProdDbContractData
    opt: Array<ProdDbOptEntry>
    multipleSelectOptionList: Array<ProdDbMultipleSelectOptionItem>
    distributor: Scalars['String']
    vouchers: Array<ProdDbVoucherData>
    b2b: Scalars['Boolean']
    vzfID: Scalars['String']
    bookingId: Scalars['String']
    createdAt: Scalars['Int']
    clientID?: Maybe<Scalars['Int']>
    clientCompany?: Maybe<Scalars['String']>
    status: Scalars['String']
    source: Scalars['String']
    salesPartner?: Maybe<Scalars['String']>
    wowi?: Maybe<Scalars['String']>
}

export type ProdDbPersonalData = {
    __typename?: 'ProdDBPersonalData'
    zipcode: Scalars['String']
    city: Scalars['String']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    country: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
}

export type ProdDbPortabilityContractHolder = {
    __typename?: 'ProdDBPortabilityContractHolder'
    firstName: Scalars['String']
    lastName: Scalars['String']
}

export type ProdDbPortabilityData = {
    __typename?: 'ProdDBPortabilityData'
    address: ProdDbAddressData
    contractHolderOptions: Array<ProdDbPortabilityContractHolder>
    endOfContract: Scalars['String']
    phoneOptions: Array<ProdDbPortabilityTelephone>
    selectedProvider: Scalars['String']
    selectedRadios: Array<Scalars['String']>
    landLineContractOptionsExit: Scalars['Boolean']
}

export type ProdDbPortabilityTelephone = {
    __typename?: 'ProdDBPortabilityTelephone'
    areaCode: Scalars['String']
    number: Scalars['String']
}

export type ProdDbProduct = {
    __typename?: 'ProdDBProduct'
    id: Scalars['String']
    internalID: Scalars['String']
    title: Scalars['String']
    internalName: Scalars['String']
    sortIndex: Scalars['Int']
    description: Scalars['String']
    Information: Scalars['String']
    pibInfo: Scalars['String']
    pibLink: Scalars['String']
    BillMode: Scalars['String']
    PriceGross: Scalars['Float']
    PriceNet: Scalars['Float']
    state: Scalars['String']
    marketingFrom: Scalars['Int']
    provisionFrom: Scalars['Int']
    contractLength: Scalars['String']
    type: Scalars['String']
    optional: Scalars['Boolean']
    subproducts: Array<ProdDbProduct>
    Discounts: Array<ProdDbDiscount>
    Fees: Array<ProdDbFee>
    OptionsGroups: Array<ProdDbProductOptionGroupEntry>
    Upload: Scalars['String']
    UploadStandard: Scalars['String']
    UploadMinimal: Scalars['String']
    Download: Scalars['String']
    DownloadStandard: Scalars['String']
    DownloadMinimal: Scalars['String']
}

export type ProdDbProductInput = {
    id: Scalars['String']
    internalID: Scalars['String']
    title: Scalars['String']
    internalName: Scalars['String']
    sortIndex: Scalars['Int']
    description: Scalars['String']
    Information: Scalars['String']
    pibInfo: Scalars['String']
    pibLink: Scalars['String']
    BillMode: Scalars['String']
    PriceGross: Scalars['Float']
    PriceNet: Scalars['Float']
    state: Scalars['String']
    marketingFrom: Scalars['Int']
    provisionFrom: Scalars['Int']
    contractLength: Scalars['String']
    type: Scalars['String']
    optional: Scalars['Boolean']
    subproducts: Array<Scalars['String']>
    OptionsGroups: Array<ProdDbProductOptionGroupEntryInput>
    Discounts: Array<ProdDbDiscountInput>
    Fees: Array<ProdDbFeeInput>
    Upload: Scalars['String']
    UploadStandard: Scalars['String']
    UploadMinimal: Scalars['String']
    Download: Scalars['String']
    DownloadStandard: Scalars['String']
    DownloadMinimal: Scalars['String']
}

export type ProdDbProductOptionGroupEntry = {
    __typename?: 'ProdDBProductOptionGroupEntry'
    id: Scalars['String']
    name: Scalars['String']
}

export type ProdDbProductOptionGroupEntryInput = {
    id: Scalars['String']
    name: Scalars['String']
}

export type ProdDbProductPartialInput = {
    internalID?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    sortIndex?: Maybe<Scalars['Int']>
    description?: Maybe<Scalars['String']>
    Information?: Maybe<Scalars['String']>
    pibInfo?: Maybe<Scalars['String']>
    pibLink?: Maybe<Scalars['String']>
    BillMode?: Maybe<Scalars['String']>
    PriceGross?: Maybe<Scalars['Float']>
    PriceNet?: Maybe<Scalars['Float']>
    state?: Maybe<Scalars['String']>
    marketingFrom?: Maybe<Scalars['Int']>
    provisionFrom?: Maybe<Scalars['Int']>
    contractLength?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    optional?: Maybe<Scalars['Boolean']>
    subproducts?: Maybe<Array<Scalars['String']>>
    OptionsGroups?: Maybe<Array<Maybe<ProdDbProductOptionGroupEntryInput>>>
    Discounts?: Maybe<Array<Maybe<ProdDbDiscountInput>>>
    Fees?: Maybe<Array<Maybe<ProdDbFeeInput>>>
    Upload?: Maybe<Scalars['String']>
    UploadStandard?: Maybe<Scalars['String']>
    UploadMinimal?: Maybe<Scalars['String']>
    Download?: Maybe<Scalars['String']>
    DownloadStandard?: Maybe<Scalars['String']>
    DownloadMinimal?: Maybe<Scalars['String']>
}

export type ProdDbSelectedOptionCategory = {
    __typename?: 'ProdDBSelectedOptionCategory'
    id: Scalars['String']
    selectedOptions: Array<Scalars['String']>
}

export type ProdDbSelectedProduct = {
    __typename?: 'ProdDBSelectedProduct'
    id: Scalars['String']
    productTypes: Array<ProdDbSelectedProductType>
}

export type ProdDbSelectedProductCategory = {
    __typename?: 'ProdDBSelectedProductCategory'
    id: Scalars['String']
    selectedProduct?: Maybe<ProdDbSelectedProduct>
}

export type ProdDbSelectedProductType = {
    __typename?: 'ProdDBSelectedProductType'
    id: Scalars['String']
    optionCategories: Array<ProdDbSelectedOptionCategory>
}

export type ProdDbVoucherData = {
    __typename?: 'ProdDBVoucherData'
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    currency: VoucherCurrency
    discountType: Scalars['String']
    month: Scalars['Int']
    value: Scalars['Float']
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type Product = {
    __typename?: 'Product'
    id: Scalars['ID']
    title: Scalars['String']
    clientID?: Maybe<Scalars['String']>
    startOfContract?: Maybe<Scalars['Int']>
    supplyStart?: Maybe<Scalars['Int']>
    marketingFrom?: Maybe<Scalars['Int']>
    provisionFrom?: Maybe<Scalars['Int']>
    titleBasket: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    descriptionBasket: Scalars['String']
    dataSheet?: Maybe<Scalars['String']>
    badgeIdentifier?: Maybe<Scalars['String']>
    subCategory?: Maybe<Scalars['String']>
    productTypes: Array<ProductType>
    contractLength: Scalars['String']
    information?: Maybe<Scalars['String']>
    pibInfo?: Maybe<Scalars['String']>
    pibLink?: Maybe<Scalars['String']>
    billMode: BillMode
    included: Scalars['Boolean']
    excludedArticles: Array<Scalars['String']>
    requiredArticles: Array<Scalars['String']>
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discounts: Array<Discount>
    fees: Array<Fee>
    sortIndex: Scalars['Int']
    footnoteText: Scalars['String']
    minimumContractPeriod: Scalars['String']
    cancellationPeriod: Scalars['String']
    contractExtension: Scalars['String']
}

export type ProductCategory = {
    __typename?: 'ProductCategory'
    id: Scalars['ID']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    priceText: Scalars['String']
    products: Array<Product>
    sortIndex: Scalars['Int']
}

export type ProductCategoryConfiguration = {
    __typename?: 'ProductCategoryConfiguration'
    enablePage: Scalars['Boolean']
    categoryDependencies: Array<CategoryDependencies>
}

export type ProductDiscount = {
    __typename?: 'ProductDiscount'
    id: Scalars['ID']
    title: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discountLength: Scalars['Int']
}

export type ProductDiscountInput = {
    id: Scalars['ID']
    title: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discountLength: Scalars['Int']
}

export type ProductFree = {
    __typename?: 'ProductFree'
    id: Scalars['String']
    title: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discounts: Array<ProductDiscount>
}

export type ProductFreeInput = {
    id: Scalars['String']
    title: Scalars['String']
    billMode: BillMode
    gross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    discounts: Array<ProductDiscountInput>
}

export type ProductOption = {
    __typename?: 'ProductOption'
    id: Scalars['String']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle: Scalars['String']
    description: Scalars['String']
    information: Scalars['String']
    dataSheet: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    isHardware: Scalars['Boolean']
    contractLength: Scalars['String']
    fees: Array<ProductFree>
    discounts: Array<ProductDiscount>
}

export type ProductOptionGroup = {
    __typename?: 'ProductOptionGroup'
    id: Scalars['String']
    title: Scalars['String']
    type: Scalars['String']
    identifier: Scalars['String']
    options: Array<ProductOption>
}

export type ProductOptionGroupInput = {
    id: Scalars['String']
    title: Scalars['String']
    type: Scalars['String']
    identifier: Scalars['String']
    options: Array<ProductOptionInput>
}

export type ProductOptionInput = {
    id: Scalars['String']
    title: Scalars['String']
    titleBasket: Scalars['String']
    subtitle: Scalars['String']
    description: Scalars['String']
    information: Scalars['String']
    dataSheet: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    isHardware: Scalars['Boolean']
    contractLength: Scalars['String']
    fees: Array<ProductFreeInput>
    discounts: Array<ProductDiscountInput>
}

export type ProductType = {
    __typename?: 'ProductType'
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle?: Maybe<Scalars['String']>
    information?: Maybe<Scalars['String']>
    pibInfo?: Maybe<Scalars['String']>
    pibLink?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    dataSheet?: Maybe<Scalars['String']>
    category: Array<OptionCategory>
    billMode?: Maybe<BillMode>
    included?: Maybe<Scalars['Boolean']>
    isDefault?: Maybe<Scalars['Boolean']>
    optional?: Maybe<Scalars['Boolean']>
    gross?: Maybe<Scalars['Float']>
    priceNet?: Maybe<Scalars['Float']>
    priceVat?: Maybe<Scalars['Float']>
    sortIndex: Scalars['Int']
    discounts: Array<Discount>
    footnoteText: Scalars['String']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
}

export type ProductTypes = {
    __typename?: 'ProductTypes'
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle: Scalars['String']
    information: Scalars['String']
    pibInfo: Scalars['String']
    pibLink: Scalars['String']
    description: Scalars['String']
    productInfoSheet: Scalars['String']
    subCategory: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    optional: Scalars['Boolean']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
    discounts: Array<ProductDiscount>
    category: Array<ProductOptionGroup>
}

export type ProductTypesInput = {
    id: Scalars['String']
    title: Scalars['String']
    identifier: Scalars['String']
    subtitle: Scalars['String']
    information: Scalars['String']
    pibInfo: Scalars['String']
    pibLink: Scalars['String']
    description: Scalars['String']
    productInfoSheet: Scalars['String']
    subCategory: Scalars['String']
    billMode: BillMode
    priceGross: Scalars['Float']
    priceNet: Scalars['Float']
    priceVat: Scalars['Float']
    included: Scalars['Boolean']
    isDefault: Scalars['Boolean']
    optional: Scalars['Boolean']
    upload: Scalars['String']
    uploadStandard: Scalars['String']
    uploadMinimal: Scalars['String']
    download: Scalars['String']
    downloadStandard: Scalars['String']
    downloadMinimal: Scalars['String']
    discounts: Array<ProductDiscountInput>
    category: Array<ProductOptionGroupInput>
}

export type Provisioning = {
    __typename?: 'Provisioning'
    id: Scalars['ID']
    state: Scalars['String']
    orderNr: Scalars['String']
    orderDate: Scalars['Time']
    oneTimeCost: Scalars['Float']
    monthlyCost: Scalars['Float']
    orderedProducts: Array<OrderedProduct>
    activeContract: Scalars['String']
    contractStart: Scalars['Time']
    source: Scalars['String']
    cancelReason: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    availableCities: Array<Scalars['String']>
    availableStreets: Array<Scalars['String']>
    availableDistricts: Array<Scalars['String']>
    availableHouseNumbers: Array<Scalars['String']>
    availableAdditions: Array<Scalars['String']>
    availableProducts: AvailableProductsResponse
    checkVZF?: Maybe<CheckVzfResponse>
    generateVZFID: Scalars['String']
    loadOrderProgress?: Maybe<LoadOrderProgressResponse>
    validateManualVoucher?: Maybe<VoucherData>
    validateNonManualVoucher: Array<VoucherData>
    loadClientData: Client
    loadOrderProcessData: Customize
    loadCATVData: CatvCustomerDataResponse
    searchForProducts: Array<VoucherProduct>
    searchForDiscounts: Array<VoucherProduct>
    loadUserList: Array<User>
    loadUser: User
    loadLoggedUser: LoggedUser
    loadVouchers: Array<Voucher>
    loadMarketingList: Array<Marketing>
    loadMarketing: Marketing
    loadAddresses: PostCodeResponse
    loadAddressesDetail: PostCodeDetail
    loadBookings: Array<Booking>
    loadProvisionings: Array<Provisioning>
    loadClient: Array<Client>
    loadNotAssignedDistributor: Array<User>
    loadProdDBAddresses: Array<ProdDbAddress>
    loadProdDBProduct: Array<ProdDbProduct>
    loadProdDBOrders: Array<ProdDbOrder>
    loadProdDBCategory: Array<ProdDbCategory>
    loadProdDBOption: Array<ProdDbOption>
    loadProdDBOptionGroup: Array<ProdDbOptionGroup>
}

export type QueryAvailableCitiesArgs = {
    zipcode: Scalars['String']
}

export type QueryAvailableStreetsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    district?: Maybe<Scalars['String']>
}

export type QueryAvailableDistrictsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
}

export type QueryAvailableHouseNumbersArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    district?: Maybe<Scalars['String']>
    street: Scalars['String']
}

export type QueryAvailableAdditionsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    district?: Maybe<Scalars['String']>
    street: Scalars['String']
    houseNumber: Scalars['String']
}

export type QueryAvailableProductsArgs = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    district?: Maybe<Scalars['String']>
    addition?: Maybe<Scalars['String']>
    B2B: Scalars['Boolean']
    loadTestData?: Maybe<Scalars['Boolean']>
    productGroups: Array<Scalars['String']>
}

export type QueryCheckVzfArgs = {
    vzf: Scalars['String']
}

export type QueryLoadOrderProgressArgs = {
    id: Scalars['String']
}

export type QueryValidateManualVoucherArgs = {
    input: ValidateManualVoucherInput
}

export type QueryValidateNonManualVoucherArgs = {
    input: ValidateNonManualVoucherInput
}

export type QueryLoadClientDataArgs = {
    id: Scalars['String']
}

export type QueryLoadOrderProcessDataArgs = {
    type: Scalars['String']
}

export type QueryLoadCatvDataArgs = {
    data: Scalars['String']
    productGroups: Array<Scalars['String']>
}

export type QuerySearchForProductsArgs = {
    search: Scalars['String']
}

export type QuerySearchForDiscountsArgs = {
    search: Scalars['String']
}

export type QueryLoadUserArgs = {
    id: Scalars['String']
}

export type QueryLoadLoggedUserArgs = {
    id: Scalars['String']
}

export type QueryLoadMarketingArgs = {
    id: Scalars['String']
}

export type QueryLoadAddressesArgs = {
    input: PostCodeInput
}

export type QueryLoadAddressesDetailArgs = {
    context: Scalars['String']
}

export type QueryLoadNotAssignedDistributorArgs = {
    id: Scalars['String']
}

export type QueryLoadProdDbOrdersArgs = {
    clientID?: Maybe<Scalars['String']>
    salesPartner?: Maybe<Scalars['String']>
}

export type Response = {
    __typename?: 'Response'
    status: Scalars['String']
    message?: Maybe<Scalars['String']>
}

export type SelectedOptionCategory = {
    id: Scalars['String']
    selectedOptions: Array<Scalars['String']>
}

export type SelectedProduct = {
    id: Scalars['String']
    productTypes: Array<SelectedProductType>
}

export type SelectedProductCategory = {
    id: Scalars['String']
    selectedProduct?: Maybe<SelectedProduct>
}

export type SelectedProductType = {
    id: Scalars['String']
    optionCategories: Array<SelectedOptionCategory>
}

export type ShowPortability = {
    __typename?: 'ShowPortability'
    identifier: Scalars['String']
    wishValue: Scalars['String']
}

export type TelephoneConfiguration = {
    __typename?: 'TelephoneConfiguration'
    identifier: Scalars['String']
    link?: Maybe<ConfigurationLink>
    helpText?: Maybe<Scalars['String']>
    required?: Maybe<Scalars['Boolean']>
    type: ConfigurationType
    displayType?: Maybe<ConfigurationDisplayType>
    options?: Maybe<Array<OptionConfiguration>>
    condition?: Maybe<ConditionConfiguration>
    render?: Maybe<Array<TelephoneConfiguration>>
    noMarginBottom?: Maybe<Scalars['Boolean']>
    marginTop?: Maybe<Scalars['String']>
    titleHidden?: Maybe<Scalars['Boolean']>
}

export type TelephoneOptions = {
    __typename?: 'TelephoneOptions'
    enablePage: Scalars['Boolean']
    enablePortabilityPage: Scalars['Boolean']
    telephoneConfiguration: Array<TelephoneConfiguration>
}

export type TotalDiscounts = {
    sum: Scalars['String']
    unlimitedVoucherCost?: Maybe<Scalars['String']>
    discounts: Array<VzfpdfDiscount>
}

export type TouchPointEntry = {
    __typename?: 'TouchPointEntry'
    label: Scalars['String']
    hasInput: Scalars['Boolean']
}

export type TouchPointInput = {
    bookingID: Scalars['String']
    key: Scalars['String']
    value: Scalars['String']
}

export type TransferFeeOption = {
    __typename?: 'TransferFeeOption'
    feeId: Scalars['String']
    visibleB2B: Scalars['Boolean']
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type UserDb = {
    __typename?: 'UserDB'
    id: Scalars['ID']
    keycloakId: Scalars['ID']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type UserInput = {
    id: Scalars['String']
    customerNumber: Scalars['String']
    title: Scalars['String']
    name: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    telephone: Scalars['String']
    company: Scalars['String']
    active: Scalars['Boolean']
    roles: Array<Scalars['String']>
}

export type VfzVoucherData = {
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    products: Array<VoucherProductInput>
    actions: Array<VoucherProductInput>
    value: VzfVoucherValue
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VzfConfiguration = {
    __typename?: 'VZFConfiguration'
    disablePage: Scalars['Boolean']
    optionalFees: Array<CustomizeEntry>
    footNoteText: Array<CustomizeEntry>
    introText: Array<Scalars['String']>
}

export type VzfData = {
    __typename?: 'VZFData'
    selectedProductCategories: Scalars['String']
    multipleSelectOptionList: Array<MultipleSelectOptions>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    email: Scalars['String']
    customizeType: Scalars['String']
    clientID?: Maybe<Scalars['Int']>
    clientData?: Maybe<Client>
    vouchers: Array<VoucherData>
}

export type VzfDataInput = {
    selectedProductCategories: Scalars['String']
    multipleSelectOptionList: Array<MultipleSelectOptionItem>
    startOfMarketing?: Maybe<Scalars['Time']>
    startOfDelivery?: Maybe<Scalars['Time']>
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    additionalInfo: Scalars['String']
    email: Scalars['String']
    path: Scalars['String']
    customizeType: Scalars['String']
    clientID?: Maybe<Scalars['Int']>
    vouchers: Array<VfzVoucherData>
}

export type VzfpdfCategoryCost = {
    title: Scalars['String']
    sort: Scalars['Int']
    entries: Array<VzfpdfEntry>
}

export type VzfpdfCost = {
    monthlyCost: Scalars['String']
    oneTimeCost: Scalars['String']
    monthlyItems: Array<VzfpdfEntry>
    oneTimeItems: Array<VzfpdfEntry>
    categoryCost: Array<VzfpdfCategoryCost>
    totalDiscounts: TotalDiscounts
}

export type VzfpdfData = {
    vzfID: Scalars['String']
    text: VzfpdfStaticText
    functionalFeaturesDisability: Scalars['String']
    costHint1: Scalars['String']
    costHint2: Scalars['String']
    costHint3: Scalars['String']
    optionalCosts: Array<VzfpdfEntry>
    services: Array<VzfpdfServices>
    devices: Array<Scalars['String']>
    costs: VzfpdfCost
    internet?: Maybe<VzfpdfInternet>
    contractTerms: Array<Scalars['String']>
    products: Array<Scalars['String']>
    clientName?: Maybe<Scalars['String']>
}

export type VzfpdfDiscount = {
    name: Scalars['String']
    oldPrice: Scalars['String']
    newPrice: Scalars['String']
}

export type VzfpdfEntry = {
    title: Scalars['String']
    value: Scalars['String']
    billMode?: Maybe<BillMode>
}

export type VzfpdfInternet = {
    speedTerm: Scalars['String']
    upload: VzfpdfSpeed
    download: VzfpdfSpeed
}

export type VzfpdfServices = {
    type: Scalars['String']
    title: Scalars['String']
    information: Array<Scalars['String']>
}

export type VzfpdfSpeed = {
    max: Scalars['String']
    normal: Scalars['String']
    min: Scalars['String']
}

export type VzfpdfStaticText = {
    intro: Array<Scalars['String']>
    footer: Array<FooterContent>
}

export type VzfVoucherValue = {
    currency: VoucherCurrency
    discountType: Scalars['String']
    month: Scalars['Int']
    value: Scalars['Int']
}

export type ValidateManualVoucherInput = {
    code: Scalars['String']
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    products: Array<Scalars['String']>
}

export type ValidateNonManualVoucherInput = {
    zipcode: Scalars['String']
    city: Scalars['String']
    street: Scalars['String']
    housenumber: Scalars['String']
    products: Array<Scalars['String']>
}

export type Voucher = {
    __typename?: 'Voucher'
    active: Scalars['Boolean']
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    isRestrictedLocation: Scalars['Boolean']
    addresses: Array<Address>
    name: Scalars['String']
    isValidOnAddress: Scalars['Boolean']
    products: Array<VoucherProduct>
    actions: Array<VoucherProduct>
    redeemed: Scalars['Int']
    redemptionFrequency: Scalars['Int']
    validFrom?: Maybe<Scalars['Time']>
    dateOfExpiry?: Maybe<Scalars['Time']>
    value: VoucherValue
    infoText: Scalars['String']
    articleNumber: Scalars['String']
    voucherRedeemedList: Array<Scalars['Time']>
}

export type VoucherConfiguration = {
    __typename?: 'VoucherConfiguration'
    enableAutomatic: Scalars['Boolean']
    enableManualPage: Scalars['Boolean']
}

export enum VoucherCurrency {
    EURO = 'EURO',
    PERCENT = 'PERCENT',
}

export type VoucherData = {
    __typename?: 'VoucherData'
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    products: Array<VoucherProduct>
    actions: Array<VoucherProduct>
    value: VoucherValue
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherDataInput = {
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    name: Scalars['String']
    currency: VoucherCurrency
    discountType: Scalars['String']
    month: Scalars['Int']
    value: Scalars['Float']
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherInput = {
    active: Scalars['Boolean']
    code: Scalars['String']
    id: Scalars['ID']
    type: Scalars['String']
    isRestrictedLocation: Scalars['Boolean']
    addresses: Array<AddressData>
    name: Scalars['String']
    isValidOnAddress: Scalars['Boolean']
    products: Array<VoucherProductInput>
    actions: Array<VoucherProductInput>
    redeemed: Scalars['Int']
    redemptionFrequency: Scalars['Int']
    validFrom?: Maybe<Scalars['Time']>
    dateOfExpiry?: Maybe<Scalars['Time']>
    value: VoucherValueInput
    infoText: Scalars['String']
    articleNumber: Scalars['String']
}

export type VoucherProduct = {
    __typename?: 'VoucherProduct'
    articleId: Scalars['String']
    name: Scalars['String']
    price: Scalars['Float']
    type: Scalars['String']
}

export type VoucherProductInput = {
    articleId: Scalars['String']
    name: Scalars['String']
    price: Scalars['Float']
    type: Scalars['String']
}

export type VoucherValue = {
    __typename?: 'VoucherValue'
    currency: VoucherCurrency
    isOnce: Scalars['Boolean']
    discountType: Scalars['String']
    month: Scalars['Int']
    value: Scalars['Int']
}

export type VoucherValueInput = {
    currency: VoucherCurrency
    discountType: Scalars['String']
    month: Scalars['Int']
    value: Scalars['Int']
}
